@import 'lifeUtil.scss';

.reserv_wrap {
  background-color: $back-color;
  width: 100%;
  height: calc(100% - 40px);
  position: relative;

  .customer_btn_box3 {
    padding: 0 0 10px 0;

    .customer_btn_cate1 {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      border-radius: 10px;
      padding: 40px 50px;
      cursor: pointer;
      color: #676767;
      font-size: 40px;
      font-family: 'Pretendard_b';
      border: 2px solid #c993ff;
      background: #fff;

      .reserv_logo_cate1 {
        @include background-set(25px, 25px, contain);
        background-image: url('../assets/images/cate_sub_al1.png');
        display: inline-block;
        vertical-align: middle;
      }

      .arrow_logo_cate1 {
        @include background-set(20px, 20px, contain);
        background-image: url('../assets/images/cate_sub_logo1.png');
        display: inline-block;
        vertical-align: middle;
        text-align: right;
        background-position: right center;
        margin-right: 8px;
      }

      .reserv_text_cate1 {
        display: inline-block;
        vertical-align: middle;
        padding-left: 10px;
      }
    }
    .customer_btn_cate2 {
      display: grid;
      grid-template-columns: auto 1fr auto; /* 첫 번째 요소는 자동 크기, 두 번째 요소는 남은 공간을 차지, 세 번째 요소는 자동 크기 */
      align-items: center;
      //box-shadow: 0px 10px 20px #00000029;
      border-radius: 10px;
      padding: 40px 50px;
      cursor: pointer;
      color: #676767;
      font-size: 40px;
      font-family: 'Pretendard_b';
      border: 2px solid #7f9dff;
      background: #fff;

      .reserv_logo_cate2 {
        @include background-set(25px, 25px, contain);
        background-image: url('../assets/images/cate_sub_al2.png');
        display: inline-block;
        vertical-align: middle;
      }

      .arrow_logo_cate2 {
        @include background-set(20px, 20px, contain);
        background-image: url('../assets/images/cate_sub_logo2.png');
        display: inline-block;
        vertical-align: middle;
        text-align: right;
        background-position: right center;
        margin-right: 8px;
      }

      .reserv_text_cate2 {
        display: inline-block;
        vertical-align: middle;
        padding-left: 10px;
      }
    }
    .customer_btn_cate3 {
      display: grid;
      grid-template-columns: auto 1fr auto; /* 첫 번째 요소는 자동 크기, 두 번째 요소는 남은 공간을 차지, 세 번째 요소는 자동 크기 */
      align-items: center;
      //box-shadow: 0px 10px 20px #00000029;
      border-radius: 10px;
      padding: 40px 50px;
      cursor: pointer;
      font-size: 40px;
      font-family: 'Pretendard_b';
      background: #fff;
      color: #676767;
      border: 2px solid #ffc461;

      .reserv_logo_cate3 {
        @include background-set(25px, 25px, contain);
        background-image: url('../assets/images/cate_sub_al3.png');
        display: inline-block;
        vertical-align: middle;
        flex-shrink: 0; // 크기 줄지 않도록 설정
      }

      .arrow_logo_cate3 {
        @include background-set(20px, 20px, contain);
        background-image: url('../assets/images/cate_sub_logo3.png');
        display: inline-block;
        vertical-align: middle;
        text-align: right;
        background-position: right center;
        margin-right: 8px;
      }

      .reserv_text_cate3 {
        display: inline-block;
        vertical-align: middle;
        padding-left: 10px;
        flex-shrink: 0; // 크기 줄지 않도록 설정
      }
    }

    .customer_btn_cate4 {
      display: grid;
      grid-template-columns: auto 1fr auto; /* 첫 번째 요소는 자동 크기, 두 번째 요소는 남은 공간을 차지, 세 번째 요소는 자동 크기 */
      align-items: center;
      //box-shadow: 0px 10px 20px #00000029;
      border-radius: 10px;
      padding: 40px 50px;
      cursor: pointer;
      color: #676767;
      font-size: 40px;
      font-family: 'Pretendard_b';
      border: 2px solid #e39fa1;
      background: #fff;

      .reserv_logo_cate4 {
        @include background-set(25px, 25px, contain);
        background-image: url('../assets/images/cate_sub_al4.png');
        display: inline-block;
        vertical-align: middle;
      }

      .arrow_logo_cate4 {
        @include background-set(20px, 20px, contain);
        background-image: url('../assets/images/cate_sub_logo4.png');
        display: inline-block;
        vertical-align: middle;
        text-align: right;
        background-position: right center;
        margin-right: 8px;
      }

      .reserv_text_cate4 {
        display: inline-block;
        vertical-align: middle;
        padding-left: 10px;
      }
    }
    .customer_btn_cate5 {
      display: grid;
      grid-template-columns: auto 1fr auto; /* 첫 번째 요소는 자동 크기, 두 번째 요소는 남은 공간을 차지, 세 번째 요소는 자동 크기 */
      align-items: center;
      //box-shadow: 0px 10px 20px #00000029;
      border-radius: 10px;
      padding: 40px 50px;
      cursor: pointer;
      color: #676767;
      font-size: 40px;
      font-family: 'Pretendard_b';
      border: 2px solid #59d67c;
      background: #fff;

      .reserv_logo_cate5 {
        @include background-set(25px, 25px, contain);
        background-image: url('../assets/images/cate_sub_al5.png');
        display: inline-block;
        vertical-align: middle;
      }

      .arrow_logo_cate5 {
        @include background-set(20px, 20px, contain);
        background-image: url('../assets/images/cate_sub_logo5.png');
        display: inline-block;
        vertical-align: middle;
        text-align: right;
        background-position: right center;
        margin-right: 8px;
      }

      .reserv_text_cate5 {
        display: inline-block;
        vertical-align: middle;
        padding-left: 10px;
      }
    }
  }

  &.overflow {
    overflow: hidden !important;
  }

  &.self {
    height: 100%;
  }

  .back_btn_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 0 5px 10px !important;
    background-color: #fff !important;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 2;
    text-align: left;

    &.self {
      top: 0;
    }

    .back_btn {
      //background-color: #00d07f;
      color: #017aff;
      font-size: 15px;
      position: relative;
      padding: 9px 25px 9px 44px;
      display: inline-block;
      border-radius: 10px;
      cursor: pointer;
      font-weight: 600;

      &::after {
        position: absolute;
        content: '';
        top: 49%;
        left: 12px;
        transform: translate(0, -50%);
        @include background-set(12px, 12px, contain);
        background-image: url('../assets/images/customer_back.png');
      }
    }
  }

  .reserv_back {
    width: 93%;
    margin: 0 auto;
    padding: 75px 0 !important;

    position: relative;

    &.success {
      padding: 0;
      height: 100%;
    }

    &.sign {
      padding: 60px 0;
      overflow: hidden !important;
    }

    .reserv_center_box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 90%;

      .center_icon_box {
        .center_icon {
          @include background-set(130px, 130px, contain);
          background-image: url('../assets/images/self_icon.png');
          margin: 0 auto;
        }

        .center_text {
          color: #00ef81;
          padding-top: 23.5px;
          font-family: 'Pretendard_b';
          font-size: 30px;
        }
      }

      .center_info_box {
        color: #676767;
        padding-top: 32px;
        font-size: 20px;
        padding-bottom: 32px;
      }
      .center_home_box {
        color: #676767;
        padding: 10px;
        border-radius: 15px;
        font-size: 20px;
        display: inline-block;
        border: 1px solid #e5e5e5;
      }
    }

    .reserv_top_box {
      .reserv_title {
        font-size: 30px;
        font-family: 'Pretendard_b';

        &.sub {
          padding-top: 17px;
          padding-bottom: 17px;
          color: #414141;
          font-size: 20px;
          font-family: 'Pretendard_m';
        }
      }
    }

    .reserv_btn_box {
      padding-top: 40px;
      border-radius: 10px;

      &.last_btn {
        text-align: center;

        .reserv_btn {
          color: #fff;
          padding: 10px 37px;

          &::after {
            display: none;
          }
        }
      }

      .reserv_btn {
        //background-color: #f4f4f4;
        background-color: #017aff;
        color: #ffffff;
        font-size: 20px;
        padding: 15px 60px 15px 35px;
        border-radius: 10px;
        cursor: pointer;
        font-family: 'Pretendard_b';
        text-align: center;
        //display: inline-block;
        position: relative;

        &::after {
          position: absolute;
          content: '';
          top: 50%;
          right: 30px;
          transform: translate(0, -50%);
          @include background-set(15px, 12px, contain);
          background-image: url('../assets/images/arrow_icon_2.png');
        }
      }
    }

    .reserv_bottom_box {
      // padding-top: 20px;
      // overflow: visible;

      &.product {
        // text-align: center;
        padding-top: 0;
      }

      &.sign {
        padding-top: 35px;
      }
      .reserv_menu_box:first-child {
        border: 2px solid #3ac3ff;
        background-color: #f7fcff;
      }
      .reserv_menu_box:last-child {
        border: 2px solid #25e270;
        background-color: #25e270;
      }

      .reserv_menu_box {
        border-radius: 10px;
        background-color: #fff;
        cursor: pointer;
        padding: 38px 40px;
        margin-bottom: 10px;
        position: relative;

        &::after {
          position: absolute;
          content: '';
          @include background-set(20px, 20px, contain);
          background-image: url('../assets/images/start_arrow1.png');
          top: 50%;
          right: 50px;
          transform: translate(0, -50%);
          margin-top: 2px;
        }

        .menu_inner_box {
          .menu_icon {
            @include background_set(25px, 25px, contain);
            background-image: url('../assets/images/reserv_menu_1.png');
            display: inline-block;
            vertical-align: middle;

            &.second {
              background-image: url('../assets/images/reserv_menu_2.png');
            }

            &.third {
              background-image: url('../assets/images/reserv_menu_3.png');
            }
          }

          .menu_text {
            display: inline-block;
            vertical-align: middle;
            color: #676767;
            font-size: 35px;
            padding-left: 35px;
            font-family: 'Pretendard_b';
          }
        }
      }

      .reserv_menu_box.hospital {
        border-radius: 10px;
        background-color: #fbfffd;
        cursor: pointer;
        padding: 38px 40px;
        margin-bottom: 25px;
        position: relative;

        &::after {
          position: absolute;
          content: '';
          @include background-set(20px, 20px, contain);
          background-image: url('../assets/images/start_arrow2.png');
          top: 50%;
          right: 50px;
          transform: translate(0, -50%);
        }

        .menu_inner_box {
          .menu_icon {
            @include background_set(25px, 25px, contain);
            background-image: url('../assets/images/reserv_menu_1.png');
            display: inline-block;
            vertical-align: middle;

            &.second {
              background-image: url('../assets/images/reserv_menu_2.png');
            }

            &.third {
              background-image: url('../assets/images/reserv_menu_3.png');
            }
          }

          .menu_text {
            display: inline-block;
            vertical-align: middle;
            color: #676767;
            font-size: 35px;
            padding-left: 35px;
            font-family: 'Pretendard_b';
          }
        }
      }

      .reserv_contents_box {
        box-shadow: 0px 10px 20px #0000001d;
        border-radius: 10px;
        //background-color: #fff;
        //padding: 40px 30px;

        &.sign {
          padding: 20px;
          border-radius: 30px;
        }

        .time_box {
          width: 100%;

          .time_row {
            display: inline-block;
            vertical-align: top;
            padding-right: 10px;
            font-size: 20px;
            color: #707070;
          }

          .time_select {
            width: 100px;
            padding: 25px 30px;
            border: 1px solid #c3c3c3;
            border-radius: 10px;
            font-size: 20px;
            color: #707070;
            background-color: #fff;
            outline: none;
            margin-right: 10px;
          }
        }
      }

      .reserv_input_box.plus {
        .input_array_box {
          display: flex;
          width: 100%;
          align-items: center;

          .reserv_input {
            width: 50%;
            margin-right: 10px;
          }

          .gender-select {
            width: 10%;
            display: flex;
            align-items: center;
            margin-left: 15px;
          }

          .gender-select input[type='radio'] {
            appearance: none;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid #555;
            margin-right: 5px;
          }

          .gender-select label {
            font-size: 16px;
            cursor: pointer;
          }

          .birthday-select {
            width: 35%;
            display: flex;
          }

          .birthday-select select {
            width: 100%;
            margin-right: 5px;
          }

          .delete_input {
            width: 5%;
            display: flex;
            margin-left: auto;
            cursor: pointer;
          }
        }
      }
      .reserv_input_box {
        width: 100%;
        padding-bottom: 13px;

        .input_array_box {
          position: relative;
          margin-bottom: 13px;

          .delete_input {
            @include background_set(12px, 18px, contain);
            background-image: url('../assets/images/customer_del.png');
            position: absolute;
            top: 27%;
            right: 16px;
            cursor: pointer;
            transform: translate(0, -50%);
            font-size: 20px;
          }
        }

        .reserv_add_btn {
          color: #017aff;
          text-decoration: underline;
          font-size: 20px;
          cursor: pointer;
          padding-bottom: 12px;
        }

        .reserv_calendar {
          width: 100%;
          border-radius: 10px;
        }

        &.calendar {
          position: relative;

          &::after {
            position: absolute;
            content: '';
            top: 35px;
            right: 25px;
            transform: translate(0, -50%);
            @include background_set(24px, 28px, contain);
            background-image: url('../assets/images/cal_icon.png');
          }
        }

        .reserv_input {
          width: 100%;
          background-color: #fff;
          outline: none;
          color: #707070;
          font-size: 15px;
          padding: 12px 20px;
          border: 1px solid #c3c3c3;
          border-radius: 10px;
        }
        .reserv_birthday {
          width: 98%;
          background-color: #fff;
          outline: none;
          color: #707070;
          font-size: 20px;
          padding: 25px 30px;
          border: 1px solid #c3c3c3;
          border-radius: 10px;
          //margin-top:5px;
          button {
            background-color: #fff;
          }
        }
      }

      .reserv_equal_box {
        padding-bottom: 13px;

        .equal_label {
          font-size: 18px;
          position: relative;
          padding-left: 40px;

          &::before {
            position: absolute;
            content: '';
            width: 22px;
            height: 22px;
            top: 0;
            border-radius: 5px;
            background-color: #c9c9c9;
            left: 0;
          }

          &::after {
            position: absolute;
            content: '';
            width: 4px;
            height: 8px;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            top: 3px;
            left: 7px;
            rotate: 45deg;
          }
        }

        .equal_checkbox {
          display: none;
        }

        .equal_checkbox:checked + .equal_label::before {
          background-color: #017aff;
        }
      }

      .reserv_check_box {
        box-shadow: 0px 10px 20px #00000029;
        border-radius: 20px;
        background-color: #fff;
        padding: 50px 50px 20px;
        width: 60%;
        min-width: 300px;
        margin: 0 auto;

        .check_title_box {
          padding-bottom: 50px;

          .check_title {
            font-size: 23px;
            font-family: 'Pretendard_b';
            padding-bottom: 5px;
          }

          .check_sub {
            color: #868686;
            font-size: 20px;
          }
        }

        .reserv_text_box {
          padding: 15px 0;
          border-bottom: 1px solid #ededed;

          &.non_border {
            border-bottom: none;
          }

          .reserv_title {
            font-size: 18px;
            width: 150px;
            padding-right: 10px;
            display: inline-block;
            vertical-align: middle;
            color: #414141;
          }

          .reserv_text {
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            color: #414141;
            font-family: 'Pretendard_b';

            span {
              font-family: 'Pretendard_b';
            }
          }
        }
      }

      .terms_box {
        overflow-x: scroll;
        background-color: #f2f2f2;
        border-radius: 10px;
        padding: 20px;
        max-height: 500px;
        border: 1px solid #c3c3c3;

        &::-webkit-scrollbar {
          display: none;
        }

        .terms_title {
          display: inline-block;
          width: calc(100% - 150px);
          font-size: 20px;
          vertical-align: middle;
          padding-left: 5px;
          color: #707070;
        }

        .terms_contents_btn {
          display: inline-block;
          width: 150px;
          text-align: right;
          vertical-align: middle;
          cursor: pointer;
          color: #707070;
          padding-right: 13px;
        }

        .terms_contents_box {
          padding: 20px;
          display: none;

          &.active {
            display: block;
          }
        }
      }

      .sign_container {
        .sign_box {
          display: inline-block;
          width: 100%;
          background-color: #fff;
          vertical-align: top;

          .sign_title {
            font-size: 20px;
            font-family: 'Pretendard_bb';
            padding-bottom: 10px;
            color: #707070;
          }

          .sign_contents {
            border: 1px solid #c9c9c9;
            border-radius: 30px;
            height: 300px;
            width: 100%;
            position: relative;

            .sign {
              width: 100%;
              height: 100%;
              position: relative;
            }

            .sign_canvas {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }

        .sign_btn_box {
          padding-top: 25px;

          .sign_btn {
            display: inline-block;
            text-align: center;
            padding: 25px 85px;
            background-color: #f4f4f4;
            color: #fff;
            border-radius: 30px;
            position: relative;
            cursor: pointer;
            height: 82px;

            &::after {
              position: absolute;
              content: '';
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              @include background_set(28px, 28px, contain);
              background-image: url('../assets/images/sign_check.png');
            }

            &:first-child {
              margin-right: 10px;

              &::after {
                background-image: url('../assets/images/reset_icon.png');
              }
            }
          }
        }
      }

      .terms_checkbox {
        padding-top: 8px;

        &:first-child {
          border-bottom: 1px solid #c3c3c3;
          padding-top: 5px !important;
          font-weight: 600;
        }

        .terms_label {
          font-size: 20px;
          position: relative;
          padding-left: 40px;
          color: #707070;

          &::before {
            position: absolute;
            content: '';
            width: 22px;
            height: 22px;
            top: 0;
            border-radius: 5px;
            background-color: #c9c9c9;
            left: 0;
          }

          &::after {
            position: absolute;
            content: '';
            width: 4px;
            height: 8px;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            top: 3px;
            left: 7px;
            rotate: 45deg;
          }
        }

        .terms_checkbox {
          display: none;
        }

        .terms_checkbox:checked + .terms_label::before {
          background-color: #017aff;
        }
      }

      .slide_wrap {
        // overflow: auto;
        // white-space: nowrap;
        // height: 550px;
        // padding-top: 5px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .product_box {
        // display: inline-block;
        // width: 350px;
        // border-radius: 30px;
        // background-color: #fff;
        // position: relative;
        // height: 520px;
        // cursor: pointer;
        // margin-right: 40px;
        // box-shadow: 0px 10px 20px #00000026;

        &.color {
          background-color: #fff;
          border-radius: 15px;

          .inner_bottom_box {
            .product_btn_box {
              .product_btn {
                &:first-child {
                  background-color: #ffe9c4;
                  color: #777777;
                }
              }
            }
          }
        }

        .product_img_box {
          width: 100%;
          position: relative;
        }

        .product_img {
          position: absolute;
          top: 40px;
          left: 20px;
          transform: translate(-50%, -50%);
          @include background_set(32px, 32px, contain);
          background-image: url('../assets/images/product_card10.png');
          //국가검진
          &.sla_img_0 {
            background-image: url('../assets/images/product_card10.png');
          }
          //일반 검진
          &.img_0 {
            background-image: url('../assets/images/product_card1.png');
          }
          &.img_1 {
            background-image: url('../assets/images/product_card2.png');
          }
          &.img_2 {
            background-image: url('../assets/images/product_card3.png');
          }
          &.img_3 {
            background-image: url('../assets/images/product_card4.png');
          }
          &.img_4 {
            background-image: url('../assets/images/product_card10.png');
          }
          //차병원 검진
          &.top_img_0 {
            background-image: url('../assets/images/product_card5.png');
          }
          &.top_img_1 {
            background-image: url('../assets/images/product_card6.png');
          }
          &.top_img_2 {
            background-image: url('../assets/images/product_card7.png');
          }
          &.top_img_3 {
            background-image: url('../assets/images/product_card8.png');
          }
          &.top_img_4 {
            background-image: url('../assets/images/product_card9.png');
          }
          .img_title {
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Pretendard_bb';
            font-size: 40px;
            width: 100%;
            text-align: center;
          }
        }

        .inner_bottom_box {
          padding: 0 25px;
          white-space: break-spaces;

          .product_text_box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;

            .product_tab1 {
              width: 40%;

              .sel-boxx {
                .premium-skin {
                  position: absolute;
                  background-image: url('../assets/images/best.jpg');
                  background-size: contain;
                  background-repeat: no-repeat;
                  width: 55px;
                  height: 55px;
                  left: 45px;
                  top: 5px;
                }

                .smtpre-skin {
                  position: absolute;
                  background-image: url('../assets/images/new.jpg');
                  background-size: contain;
                  background-repeat: no-repeat;
                  width: 50px;
                  height: 50px;
                  left: 45px;
                  top: 5px;
                }
              }
            }
            .product_tab2 {
              width: 60%;
              padding-top: 10px;
            }

            .product_info_text {
              color: #959595;
              font-size: 18px;
            }

            .product_text {
              font-size: 23px;
              font-family: 'Pretendard_b';
              color: #4d4d4d;
              padding-bottom: 7px;

              &.title {
                font-size: 25px;
                color: #000000;
                padding-top: 65px;
                padding-left: 7px;
              }

              &.og_price {
                color: #626262;
                // text-decoration: line-through;
                text-decoration-color: #9b9b9b;
                font-size: 20px;
                font-family: 'Pretendard_l';
                padding-bottom: 5px;
                display: flex;
                flex-direction: column;

                .product_txt1,
                .product_txt2 {
                  display: flex;
                  justify-content: space-between;
                }

                .product_txt1 {
                  border: 1px solid #57a7ff;
                  background-color: #f4f9ff;
                  border-radius: 5px;
                  padding: 8px;
                  font-size: 12px;
                  cursor: pointer;
                }
                .product-click-btn {
                  background-image: url('../assets/images/left-click.png');
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: center;
                  width: 20px;
                  height: 20px;
                  display: inline-block;
                }
                .product-flex-wrap {
                  margin-bottom: 5px;
                  font-size: 0.8em;

                  .product-information {
                    background-image: url('../assets/images/information.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    width: 20px;
                    height: 20px;
                  }

                  &.next,
                  &.normal {
                    display: flex;
                    align-items: center; /* 세로 정렬 */
                    span {
                      padding-left: 5px;
                    }
                  }
                }
                .product_txt2 {
                  border: 1px solid #d3d3d3;
                  background-color: #fff;
                  border-radius: 5px;
                  padding: 8px;
                  font-size: 12px;
                  cursor: pointer;
                  margin-top: 10px;
                }
                .product_txt3 {
                  background-color: #f7f7f7;
                  border-radius: 5px;
                  padding: 13px 8px 13px 8px;
                  font-size: 12px;
                  margin-top: 8px;
                  text-align: center;
                  cursor: pointer;
                }
              }
            }
          }

          .product_btn_box {
            .product_btn {
              background-color: #f4f4f4;
              padding: 13px 0;
              color: #727272;
              text-align: center;
              border-radius: 15px;
              font-size: 18px;
              margin-bottom: 5px;

              &:last-child {
                background-color: inherit;
                color: #868686;
              }
            }
          }
        }
      }
    }
  }
}

.sign_canvas:hover {
  // cursor: crosshair;
}

.main_back.home.cate {
  padding-top: 80px;

  .main_sub_title {
    padding-bottom: 20px;
  }
}

.tel-wrap {
  position: fixed;
  right: 20px;
  bottom: 55px;

  a {
    text-decoration: none;
  }
  .icon-wrap {
    background-color: #017aff;
    border: 1px solid #c6c6c6;
    color: #fff;
    font-family: 'Pretendard_b';
    padding: 5px 10px;
    border-radius: 28px;

    p {
      display: inline-block;
      margin-left: 7px;
      vertical-align: middle;
    }
    .tel-icon {
      display: inline-block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../assets/images/reserv_tel.png');
      width: 40px;
      height: 40px;
      vertical-align: middle;
    }
  }
}

// 보험 점검 후 검진이란? 상세모달
.next-info-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); // 반투명 배경
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .next-info-modal {
    background: #fff;
    border-radius: 10px;
    width: 90%;
    padding: 20px 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
    position: relative;

    // 헤더 스타일
    .next-info-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 20px;

      h1 {
        font-size: 1.4em;
        color: #333;
        margin: 0;
      }

      h2 {
        font-size: 1em;
        color: #666;
        margin-top: 25px;
      }

      .next-info-close-btn {
        position: absolute;
        top: 10px;
        right: 15px;
        background: none;
        border: none;
        font-size: 1.5em;
        font-weight: bold;
        color: #666;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #333;
        }
      }
    }

    // 본문 스타일
    .next-info-body {
      font-size: 1em;
      line-height: 1.6;
      color: #555;

      p {
        font-size: 0.9em;
      }
      ul {
        margin: 20px 0;
        padding-left: 20px;

        li {
          margin-bottom: 7px;
          line-height: 1.2;

          strong {
            color: #333;
          }
        }
      }

      a {
        color: #007bff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// 애니메이션 효과
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
