@import 'lifeUtil.scss';

.screen {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
}

.menu_area {
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 21;
  min-width: 40px;
  box-shadow: 0px 3px 6px #00000029;

  &.mobile_active {
    box-shadow: 0px 3px 6px #00000029 !important;
    transition: all 500ms cubic-bezier(0.9, 0, 0.33, 1);
  }

  .side_wrap {
    height: 100%;
    width: 100%;

    .side_back {
      height: 100%;
      background-color: #fff;
      width: 100%;

      &.mobile {
        display: none;
        position: relative;
        background-color: transparent;

        .mobile_side_icon {
          width: 40px;
          height: 120px;
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 0px 36px 36px 0px;
          position: fixed;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          cursor: pointer;
          background-color: #ffffff81;
          transition: all 500ms cubic-bezier(0.9, 0, 0.33, 1);

          &::before {
            position: absolute;
            content: '';
            @include background_set(20px, 20px, contain);
            background-image: url('../assets/images/mobile_menu_icon.png');
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &.active {
            left: 220px;
            background-color: #fff;
          }
        }

        .side_menu_back {
          width: 0;
          background-color: #fff;

          &.active {
            width: 220px;
            transition: all 500ms cubic-bezier(0.9, 0, 0.33, 1);

            .side_menu_box {
              display: block;
            }
          }

          .side_menu_box {
            display: none;
          }
        }
      }

      .side_menu_back {
        width: 75px;
        height: 100%;
        transition: all 500ms cubic-bezier(0.9, 0, 0.33, 1);
        overflow: hidden;

        &.active {
          width: 220px;
        }
      }

      .side_top_box {
        width: 220px;
        padding: 30px 0 29px 5px;
        cursor: pointer;
        // margin-bottom: 20px;
        // border-bottom: 1px solid #c6c6c6;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);

        .top_icon {
          @include background_set(65px, 65px, contain);
          background-image: url('../assets/images/logo_img.png');
          display: inline-block;
          vertical-align: middle;
        }

        .top_text {
          display: inline-block;
          vertical-align: middle;
          padding-left: 25px;
          font-size: 23px;
          font-family: 'Pretendard_m';
          color: #3eb4f3;
        }
      }

      .side_menu_box {
        padding: 0 0px 50px 0px;
        width: 220px;
        height: calc(100% - 50px);

        .menu_row {
          cursor: pointer;

          &.logout {
            position: fixed;
            bottom: 0;
            padding-bottom: 35px;
          }

          &.home {
            position: fixed;
            bottom: 85px;
          }

          &.delme {
            position: fixed;
            bottom: 135px;
          }

          .main_menu_box {
            padding: 15px 0 15px 20px;

            &.active {
              background-color: $main-color;

              .menu_text {
                color: #fff;
              }

              .menu_icon {
                background-image: url('../assets/images/aside_1_white.png');

                &.second {
                  background-image: url('../assets/images/aside_2_white.png');
                }

                &.third {
                  background-image: url('../assets/images/aside_3_white.png');
                }

                &.fourth {
                  background-image: url('../assets/images/aside_4_white.png');
                }

                &.fifth {
                  background-image: url('../assets/images/aside_hospital_white.png');
                }

                &.sixth {
                  background-image: url('../assets/images/aside_product_white.png');
                }

                &.seventh {
                  background-image: url('../assets/images/aside_5_white.png');
                }

                &.last {
                  background-image: url('../assets/images/aside_link_white.png');
                }
              }
            }

            .menu_icon {
              @include background_set(30px, 30px, contain);
              background-image: url('../assets/images/aside_1_gray.png');
              display: inline-block;
              vertical-align: middle;

              &.second {
                background-image: url('../assets/images/aside_2_gray.png');
              }

              &.third {
                background-image: url('../assets/images/aside_3_gray.png');
              }

              &.fourth {
                background-image: url('../assets/images/aside_4_gray.png');
              }

              &.fifth {
                background-image: url('../assets/images/aside_hospital_gray.png');
              }

              &.sixth {
                background-image: url('../assets/images/aside_product_gray.png');
              }

              &.seventh {
                background-image: url('../assets/images/aside_5_gray.png');
              }

              &.last {
                background-image: url('../assets/images/aside_link_gray.png');
              }

              &.slas {
                background-image: url('../assets/images/aside_inspection.png');
              }
            }

            .menu_text {
              display: inline-block;
              vertical-align: middle;
              padding-left: 30px;
              font-size: 18px;
              color: #212121;
              animation: fadein 400ms ease-in-out forwards;
            }

            .delme_icon {
              @include background_set(40px, 30px, contain);
              background-image: url('../assets/images/aside_delme.png');
              display: inline-block;
              vertical-align: middle;
            }

            .home_icon {
              @include background_set(40px, 30px, contain);
              background-image: url('../assets/images/aside_home_gray.png');
              display: inline-block;
              vertical-align: middle;
            }

            .logout_icon {
              @include background_set(40px, 30px, contain);
              background-image: url('../assets/images/aside_logout_gray.png');
              display: inline-block;
              vertical-align: middle;
            }
          }

          .sub_menu_box {
            display: none;
            padding: 5px 20px 20px;
            animation: growDown 300ms ease-in-out forwards;
            transform-origin: top center;

            &.active {
              display: block;
            }

            .sub_menu {
              padding-left: 60px;
              padding-bottom: 20px;
              font-size: 15px;
              color: #212121;

              &.active {
                color: $main-color;
                font-family: 'Pretendard_b';
              }

              &:last-child {
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

.main_area {
  width: 100%;
  height: 100%;
  padding-left: 75px;
}

.header_wrap {
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;
  position: relative;
  z-index: 2;

  .header_bottom_back {
    padding: 20px;

    .header_info_box {
      position: relative;

      .info_icon {
        @include background_set(45px, 45px, contain);
        background-image: url('../assets/images/header_user_icon_2.png');
        display: inline-block;
        vertical-align: middle;
      }

      .info_text_box {
        padding-left: 10px;
        display: inline-block;
        vertical-align: middle;
        color: #414141;
        font-size: 20px;

        .info_name {
          font-family: 'Pretendard_b';
          font-size: 16px;
        }
      }
      .side_menu_open {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        transition: all 500ms cubic-bezier(0.9, 0, 0.33, 1);

        &::before {
          position: absolute;
          content: '';
          @include background_set(20px, 20px, contain);
          background-image: url('../assets/images/mobile_menu_icon.png');
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .header_top_back {
    padding: 10px 15px;
    background-color: $main-color;
    color: #fff;

    .header_info_box {
      width: 50%;
      font-size: 13px;
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;

      &.right {
        text-align: right;

        span:not(:last-child)::after {
          content: '|';
          margin: 0 7px;
        }
        span {
          cursor: pointer;
        }

        .info_text {
          //animation: blinker 3s step-start infinite;
        }

        @keyframes blinker {
          50% {
            color: crimson;
          }
        }
      }

      .info_text {
        display: inline-block;
        vertical-align: middle;
        font-family: 'Pretendard_l';
        margin-top: -2px;
        letter-spacing: 0.9px;

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .header_info_box.right {
      width: 50%;
      font-size: 13px;
      display: inline-block;
      vertical-align: middle;
      text-align: right;

      .info_text {
        padding-right: 10px;
        vertical-align: middle;
        display: inline-block;
        // animation: blinker 3s step-start infinite;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

.info_id {
  padding-top: 3px;
  font-size: 15px;
  color: #999;
  font-family: 'Pretendard_s';

  & span:not(:last-child)::after {
    content: '|';
    padding: 0 5px;
  }
}

.footer_wrap {
  position: fixed;
  bottom: 0;
  width: calc(100% - 75px);
  //background-color: #5f5f5f;
  background-color: #666;
  color: #fff;
  padding: 10.5px 0;
  text-align: center;

  &.self {
    width: 100%;

    .footer_back {
      .txt {
        padding-right: 0;

        .footer_tel {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}

.footer_back {
  width: 100%;
}

.footer_back .txt {
  font-size: 15px;
  padding-right: 20px;
  font-family: 'Pretendard_l';
}

.main_wrap {
  // padding: 50px;
  height: 100%;
  width: 100%;
  background-color: $back-color;

  .main_back {
    width: 100%;
    height: 100%;
    position: relative;

    &.home {
      padding-top: 15px;
      padding-bottom: 70px;
    }

    .customer_btn_box {
      padding: 30px 50px 0 55px;

      .customer_btn {
        display: grid;
        grid-template-columns: auto 1fr auto; /* 첫 번째 요소는 자동 크기, 두 번째 요소는 남은 공간을 차지, 세 번째 요소는 자동 크기 */
        align-items: center;
        //box-shadow: 0px 10px 20px #00000029;
        border-radius: 10px;
        padding: 50px 50px;
        cursor: pointer;
        color: #fff;
        font-size: 40px;
        font-family: 'Pretendard_b';
        background: linear-gradient(293deg, #26f7d7, #39ea7d);

        .reserv_logo {
          @include background-set(20px, 20px, contain);
          background-image: url('../assets/images/reserv_logo.png');
          display: inline-block;
          vertical-align: middle;
        }

        .arrow_logo {
          @include background-set(20px, 20px, contain);
          background-image: url('../assets/images/link_btn.png');
          display: inline-block;
          vertical-align: middle;
          text-align: right;
          background-position: right center;
        }

        .reserv_text {
          display: inline-block;
          vertical-align: middle;
          padding-left: 25px;
        }
      }
    }

    .customer_btn_box2 {
      padding: 5px 55px 15px 55px;

      .customer_btn1 {
        display: grid;
        grid-template-columns: auto 1fr auto; /* 첫 번째 요소는 자동 크기, 두 번째 요소는 남은 공간을 차지, 세 번째 요소는 자동 크기 */
        align-items: center;
        //box-shadow: 0px 10px 20px #00000029;
        border-radius: 10px;
        padding: 40px 50px;
        cursor: pointer;
        color: #fff;
        font-size: 40px;
        font-family: 'Pretendard_b';
        background: linear-gradient(321deg, rgba(239, 247, 38, 1) 0%, rgba(255, 206, 0, 1) 100%);

        .reserv_logo1 {
          @include background-set(20px, 20px, contain);
          background-image: url('../assets/images/kakao_link_btn.png');
          display: inline-block;
          vertical-align: middle;
        }

        .arrow_logo {
          @include background-set(20px, 20px, contain);
          background-image: url('../assets/images/link_btn.png');
          display: inline-block;
          vertical-align: middle;
          text-align: right;
          background-position: right center;
        }

        .reserv_text1 {
          display: inline-block;
          vertical-align: middle;
          padding-left: 25px;
        }
      }
      .customer_btn2 {
        display: grid;
        grid-template-columns: auto 1fr auto; /* 첫 번째 요소는 자동 크기, 두 번째 요소는 남은 공간을 차지, 세 번째 요소는 자동 크기 */
        align-items: center;
        //box-shadow: 0px 10px 20px #00000029;
        border-radius: 10px;
        padding: 40px 50px;
        cursor: pointer;
        color: #fff;
        font-size: 40px;
        font-family: 'Pretendard_b';
        background: linear-gradient(321deg, rgba(112, 175, 245, 1) 0%, rgba(1, 122, 255, 1) 100%);
        color: #fff;

        .reserv_logo2 {
          @include background-set(20px, 20px, contain);
          background-image: url('../assets/images/sms_link_btn.png');
          display: inline-block;
          vertical-align: middle;
        }

        .arrow_logo {
          @include background-set(20px, 20px, contain);
          background-image: url('../assets/images/link_btn.png');
          display: inline-block;
          vertical-align: middle;
          text-align: right;
          background-position: right center;
        }

        .reserv_text2 {
          display: inline-block;
          vertical-align: middle;
          padding-left: 25px;
        }
      }
      .customer_btn3 {
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding: 40px 50px;
        //cursor: pointer;
        font-size: 40px;
        font-family: 'Pretendard_b';
        background: linear-gradient(321deg, rgba(203, 203, 203, 1) 0%, rgba(150, 150, 150, 1) 100%);
        color: #fff;
        position: relative;

        .reserv_logo3 {
          @include background-set(20px, 20px, contain);
          background-image: url('../assets/images/aside_link_white.png');
          display: inline-block;
          vertical-align: middle;
          flex-shrink: 0; // 크기 줄지 않도록 설정
        }

        .link_text {
          font-size: 13px;
          padding-left: 7px;
          padding-right: 7px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1 1 auto; // 남은 공간을 차지하고 필요시 크기 줄이기
        }

        .reserv_text3 {
          display: inline-block;
          vertical-align: middle;
          padding-left: 25px;
          flex-shrink: 0; // 크기 줄지 않도록 설정
        }
        .send-btn-wrap {
          position: absolute;
          top: 50%;
          right: -20px;
          transform: translate(-50%, -50%);
          background-color: #919191;
          border-radius: 5px;
          padding: 5px 10px;
        }

        .send-btn-wrap .send-btn {
          font-size: 15px;
          color: white; /* 기본 글자색 설정 */
        }
      }
    }

    .main_product_area {
      padding: 15px 50px 70px;

      .main_product_box {
        box-shadow: 0px 10px 20px #0000001a;
        border-radius: 10px;
        background-color: #fff;

        .main_product_row {
          border-bottom: 1px solid #f0f0f0;
          padding: 5px 13px;

          &:last-child {
            border-bottom: none;
          }

          .product_icon_box {
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            width: 60px;
          }

          .product_icon {
            display: inline-block;
            @include background-set(26px, 26px, contain);
            background-image: url('../assets/images/main_icon_2.png');

            &.icon1 {
              background-image: url('../assets/images/main_icon_1.png');
            }

            &.icon3 {
              background-image: url('../assets/images/main_icon_3.png');
            }

            &.icon5 {
              background-image: url('../assets/images/main_icon_4.png');
            }
          }

          .product_text {
            display: inline-block;
            vertical-align: middle;
            color: #3e3e3e;
            font-size: 19px;
            text-align: center;

            &.title {
              font-family: 'Pretendard_b';
              width: 210px;
            }

            &.info {
              width: calc(100% - 736px);
              text-align: left;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            &.og_price {
              color: #a7a7a7;
              text-decoration: line-through;
              width: 150px;
            }

            &.price {
              font-family: 'Pretendard_b';
              width: 170px;
            }
          }

          .product_more_btn {
            display: inline-block;
            vertical-align: middle;
            background-color: #f4f4f4;
            border-radius: 10px;
            padding: 13px 60px 13px 30px;
            color: #727272;
            position: relative;
            font-family: 'Pretendard_b';
            cursor: pointer;

            &::after {
              position: absolute;
              content: '';
              top: 50%;
              right: 30px;
              transform: translate(0, -50%);
              @include background-set(16px, 16px, contain);
              background-image: url('../assets/images/main_more_icon.png');
            }
          }
        }
      }
    }
  }
}

.third-wraps {
  padding-top: 40px;
}

.main_title_box2 {
  font-size: 25px;
  font-family: 'Pretendard_bb';
  border-bottom: 1px solid #c9c9c9;
  padding: 10px 0 0 56px;
  background-color: #fff;
  padding-top: 50px;

  &.blank {
    background-color: inherit;
    border: none;
  }
}

.main_title_sub_box {
  padding: 1px 56px;
  font-size: 20px;
}

.main_title_box {
  font-size: 25px;
  font-family: 'Pretendard_bb';
  //border-bottom: 1px solid #c9c9c9;
  padding: 15px 0 0 20px;
  background-color: #fff;

  &.notice {
    padding: 15px 15px 10px 20px;
  }

  &.blank {
    background-color: inherit;
    border: none;
  }

  .total_data_box {
    display: inline-block;
    vertical-align: middle;

    .total_box {
      display: inline-block;
      font-size: 17px;
      color: #707070;
      padding-left: 25px;
    }

    //병원관리자쪽
    .total_boxh1 {
      display: inline-block;
      font-size: 17px;
      color: #707070;
    }

    //병원관리자쪽
    .total_boxhos {
      display: inline-block;
      font-size: 17px;
      color: #707070;
      padding-left: 10px;
    }

    .total_boxhos:first-child {
      padding-left: 0; /* 첫 번째 자식은 padding-left를 제거 */
    }
  }
}

.main_sub_title {
  color: #414141;
  font-size: 17px;
  padding: 5px 56px;
  font-family: 'Pretendard_s';
}

//로그인

.login_wrap {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: $back-color;
  overflow: scroll;

  .login_back {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    // height: 100%;

    .input_wrap {
      padding: 50px 0;

      .input_back {
        width: 100%;
        height: 100%;

        .logo_title_box {
          display: flex;
          justify-content: center;

          .logo_img {
            @include background_set(200px, 200px, cover);
            background-image: url('../assets/images/logo_img.png');
          }
        }

        .logo_title {
          color: #333;
          font-family: 'Pretendard_bb';
          font-size: 23px;
          line-height: 40px;
        }

        .login_check_box {
          padding-bottom: 40px;
          margin-top: -5px;

          .login_label {
            font-size: 17px;
            color: #707070;
            position: relative;
            padding-left: 30px;

            &::before {
              position: absolute;
              content: '';
              width: 20px;
              height: 20px;
              top: 0;
              border-radius: 5px;
              background-color: #c9c9c9;
              left: 0;
            }

            &::after {
              position: absolute;
              content: '';
              width: 5px;
              height: 12px;
              border-right: 3px solid #fff;
              border-bottom: 3px solid #fff;
              top: 1px;
              left: 6px;
              rotate: 45deg;
            }
          }

          .login_check {
            display: none;
          }

          .login_check:checked + .login_label::before {
            background-color: #3eb4f3;
          }
        }

        .bottom_btn_box {
          padding-top: 16px;
        }

        .bottom_btn {
          display: inline-block;
          margin-right: 18px;
          border: 1px solid #c9c9c9;
          border-radius: 10px;
          color: #707070;
          cursor: pointer;
          width: calc((100% - 18px) / 2);
          text-align: center;
          font-size: 18px;
          padding: 15px 0;
          background-color: #fff;

          &:last-child {
            margin-right: 0;
          }
        }

        .login_btn {
          background-color: #3eb4f3;
          border: none;
          width: 100%;
          border-radius: 10px;
          font-size: 23px;
          font-family: 'Pretendard_b';
          cursor: pointer;
          color: #fff;
          padding: 15px 0;
        }

        .login_input {
          display: block;
          outline: none;
          border: 1px solid #c9c9c9;
          border-radius: 10px;
          background-color: #fff;
          margin-bottom: 16px;
          width: 100%;
          font-size: 18px;
          color: #707070;
          padding: 15px 20px;
        }
      }
    }
  }
}

.back_btn_box {
  position: relative;
  border: 1px solid rgb(234, 234, 234);
}
.back_btn {
  display: inline-block;
  cursor: pointer;
  padding: 20px;
  margin-left: 20px;
  color: #3eb4f3;
  font-weight: 600;
  vertical-align: middle;

  &::after {
    position: absolute;
    content: '';
    top: 49%;
    left: 22px;
    transform: translate(0, -50%);
    @include background-set(12px, 12px, contain);
    background-image: url('../assets/images/customer_back.png');
  }
}
.register_wrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: scroll;
  background-color: #f8f8f8;

  .register_tab_area {
    padding-bottom: 43px;

    .tab_menu {
      display: inline-block;
      margin-right: 30px;
      font-size: 30px;
      font-family: 'Pretendard_b';
      font-weight: 800;
      cursor: pointer;
      color: #cecece;

      &.active {
        color: #333;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .register_back {
    &.register {
      width: 50%;
      margin: 0 auto;
      // height: 100%;
      padding: 40px 0 0;
    }

    &.search {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 50px 0;
      min-height: 630px;
      max-width: 830px;
    }

    .logo_title_box {
      padding-bottom: 50px;
      text-align: left;

      .logo_img {
        @include background_set(70px, 70px, contain);
        background-image: url('../assets/images/logo_img.png');
        display: inline-block;

        vertical-align: middle;
      }

      .logo_title {
        display: inline-block;
        color: #333;
        vertical-align: middle;
        padding-left: 0px;
        font-size: 28px;
        font-weight: 800;
        letter-spacing: 2px;
        font-family: 'Pretendard_bb';
      }
      .logo_contents {
        padding-top: 10px;
        color: #333;
      }
    }

    .register_container {
      .input_row {
        padding: 0 0 15px;

        &.certification {
          padding-top: 30px;
        }

        .confirm_msg {
          padding: 10px 0 0 150px;
          font-size: 12px;
        }

        .input_title {
          text-align: left;
          display: inline-block;
          width: 150px;
          vertical-align: middle;
          font-size: 20px;
          font-family: 'Pretendard_b';

          /* PW찾기 인증번호 INPUT BOX*/
          &.certification {
            width: 90px;
          }

          .required-input {
            vertical-align: middle; /* 세로 중앙 정렬 시도 */
            line-height: 1.5;
            padding-left: 5px;
            color: red;
          }
        }

        .phone_icon,
        .email_icon {
          display: inline-block;
          width: 20px;
          text-align: center;
          vertical-align: middle;
        }

        .search_input {
          width: 100%;
          vertical-align: middle;
          border: 1px solid #c9c9c9;
          border-radius: 10px;
          outline: none;
          padding: 15px 20px;
          font-size: 18px;
          color: $deep-gray;

          &.tel {
            width: calc(100% - 250px);
          }

          &.email {
            width: calc((100% - 180px) / 3);
          }

          &.phone {
            width: calc((100% - 40px) / 3);
          }

          &.bank {
            margin-left: 15px;
            width: calc((100% - 370px));
          }
        }

        .register_input {
          width: calc(100% - 150px);
          vertical-align: middle;
          border: 1px solid #c9c9c9;
          border-radius: 12px;
          outline: none;
          padding: 12px 20px;
          font-size: 18px;
          color: $deep-gray;

          &.code_input {
            width: calc(100% - 205px);
          }

          &.short_phone {
            width: calc((100% - 155px) / 3);
          }

          &.tel {
            width: calc(100% - 250px);
          }

          &.email {
            width: calc((100% - 190px) / 3);
          }

          &.phone {
            width: calc((100% - 190px) / 3);
          }

          &.bank {
            margin-left: 15px;
            width: calc((100% - 370px));
          }
        }

        .search_select {
          width: 100%;
          border: 1px solid #c9c9c9;
          border-radius: 10px;
          outline: none;
          padding: 15px 20px;
          font-size: 18px;
          cursor: pointer;
          color: #707070;
        }

        .register_select {
          width: calc(100% - 150px);
          border: 1px solid #c9c9c9;
          border-radius: 10px;
          outline: none;
          padding: 12px 20px;
          font-size: 18px;
          cursor: pointer;
          color: #707070;
          vertical-align: middle;

          &.email {
            width: calc((100% - 190px) / 3);
          }
        }

        .terms_label {
          font-size: 20px;
          color: #707070;
          position: relative;
          padding-left: 40px;

          &::before {
            position: absolute;
            content: '';
            width: 22px;
            height: 22px;
            top: 0;
            border-radius: 5px;
            background-color: #c9c9c9;
            left: 0;
          }

          &::after {
            position: absolute;
            content: '';
            width: 7px;
            height: 12px;
            border-right: 3px solid #fff;
            border-bottom: 3px solid #fff;
            top: 2px;
            left: 6px;
            rotate: 45deg;
          }
        }

        .terms_checkbox {
          display: none;
        }

        .terms_checkbox:checked + .terms_label::before {
          background-color: #3eb4f3;
        }
      }

      .verified {
        display: inline-block;
        padding: 7px 15px;
        margin-left: 18px;
        background-color: #3eb4f3;
        border-radius: 22px;
        font-family: 'Pretendard_bb';
        border: 3px solid #3eb4f3;
        cursor: pointer;
        color: #fff;
        vertical-align: middle;
        min-width: 77.5px;
        text-align: center;
      }

      .register_btn_box {
        width: 100%;
        text-align: center;
        padding: 35px 0;

        &.search {
          padding-bottom: 0;
        }

        .register_btn {
          background-color: #3eb4f3;
          color: #fff;
          width: 100%;
          height: 70px;
          border: none;
          border-radius: 12px;
          font-size: 20px;
          font-family: 'Pretendard_bb';
          cursor: pointer;
        }
      }
    }
  }
}

.chart-totals {
  padding: 8px 30px;
}
.list_area {
  width: 100%;
  //padding: 20px;
  box-sizing: border-box;
}

.sales-info-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.sales-info-container2.inspection {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
}

.sales-info-container2 .sales-info-item {
  width: 100%;
}

.sales-info-item {
  width: calc(20% - 10px);
  margin: 5px;
  padding: 10px;
  border: 1px solid #999999;
  min-width: 140px;
  background-color: #fff;
  font-size: 14px;
}

.sales-info-title {
  font-weight: 550;
}

.sales-info-content {
  margin-top: 5px;
}

.update_button {
  display: inline-block;
  padding: 10px 30px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.update_button:hover {
  background-color: #0056b3;
}

.super_wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  // padding: 80px 200px 15px 200px;
  padding: 65px;
  justify-content: center;
}

.super_wrap .sub-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  height: 220px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  letter-spacing: 0.7px;
  text-align: center;
  color: #6d6d6d;
  font-size: 26px;
}

.super_wrap .sub-box .sub-icon1 {
  background-image: url('../assets/images/home_total_1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-bottom: 8px;
}
.super_wrap .sub-box .sub-icon2 {
  background-image: url('../assets/images/home_total_2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-bottom: 8px;
}
.super_wrap .sub-box .sub-icon3 {
  background-image: url('../assets/images/home_total_3.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-bottom: 8px;
}
.super_wrap .sub-box .sub-icon4 {
  background-image: url('../assets/images/home_total_4.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-bottom: 8px;
}
.super_wrap .sub-box .sub-icon5 {
  background-image: url('../assets/images/home_total_5.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-bottom: 8px;
}
.super_wrap .sub-box .sub-icon6 {
  background-image: url('../assets/images/home_total_6.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-bottom: 8px;
}
.super_wrap .sub-box .sub-icon7 {
  background-image: url('../assets/images/home_total_7.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-bottom: 8px;
}
.super_wrap .sub-box .sub-icon8 {
  background-image: url('../assets/images/home_total_8.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-bottom: 8px;
}
.super_wrap .sub-box .sub-icon9 {
  background-image: url('../assets/images/home_total_9.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-bottom: 8px;
}
.super_wrap .sub-box > div {
  margin: 8px 0;
}
.super_wrap .sub-box > div:not(.sub-txt) {
  color: $main-color;
}

.sub-txt {
  font-size: 18px;
  color: #6d6d6d;
}

.hash_row {
  padding: 10px 5px;
  display: inline-block;
  vertical-align: middle;

  .hash_box {
    border: 1px solid $main-color;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 13px;

    .hash_text {
      display: inline-block;
      vertical-align: middle;
    }

    .hash_btn {
      display: inline-block;
      vertical-align: middle;
      padding-left: 10px;
      cursor: pointer;
      font-weight: 900;
      color: $main-color;
    }
  }
}

.mypage_area {
  padding: 50px 50px 100px;
  height: 100%;
}

.mypage_container {
  box-shadow: 0px 10px 20px #00000021;
  border-radius: 10px;
  background-color: #fff;
  padding: 30px;

  &.top {
    margin-bottom: 35px;
  }

  .profile_box {
    .profile_img_box {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 110px);

      .profile_img {
        @include background-set(102px, 102px, contain);
        background-image: url('../assets/images/header_user_icon_2.png');
      }
    }

    .profile_btn_box {
      display: inline-block;
      vertical-align: middle;

      .profile_btn {
        border: 1px solid #c3c3c3;
        border-radius: 10px;
        padding: 10px 37px;
        color: #6f7070;
        font-size: 20px;
        cursor: pointer;

        &.edit {
          border: none;
          background-color: #f4f4f4;
          margin-bottom: 10px;
        }
      }
    }
  }

  .mypage_title_box {
    .mypage_title {
      font-size: 25px;
      color: #414141;
      padding-bottom: 25px;

      &.sub {
        font-size: 15px;
      }
    }
  }

  .mypage_contents_box {
    .my_row {
      padding: 10px 0;
      border-bottom: 1px solid #e3e4e3;

      &:last-child {
        border-bottom: none;
      }

      .my_text {
        font-size: 20px;
        color: #414141;
        font-family: 'Pretendard_b';
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 270px);
        line-height: 1.5;

        &.title {
          font-family: 'Pretendard_m';
          width: 150px;
        }

        & p {
          display: inline;

          &:not(:first-child) {
            padding-left: 20px;
          }
        }

        .mypage_input {
          display: inline-block;
          padding: 10px 15px;
          border: 1px solid #c9c9c9;
          border-radius: 10px;
          outline: 0 none;
          color: #707070;
          font-size: 17px;
          margin-right: 10px;
          vertical-align: middle;
        }

        .mypage_select {
          padding: 10px 15px;
          border: 1px solid #c9c9c9;
          border-radius: 10px;
          margin-right: 13px;
          outline: 0 none;
          color: #707070;
          font-size: 17px;
          vertical-align: middle;

          option {
            border-radius: 5px;
          }
        }
      }

      .my_btn {
        display: inline-block;
        vertical-align: middle;
        border: 1px solid #c3c3c3;
        border-radius: 10px;
        padding: 5px 20px;
        color: #6f7070;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}

.side_menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%; /* 화면을 가득 채우도록 너비 설정 */
  height: 100%;
  background-color: #fff;
  transition: transform 0.3s ease-out; /* 슬라이딩 효과를 위한 transition 설정 */
  z-index: 1000;
  overflow-y: auto;
  transform: translateY(-100%); /* 초기에는 화면 위로 숨김 */
}

.side_menu.open {
  transform: translateY(0); /* 화면에 보이도록 슬라이드 */
}

.side_menu_top {
  display: grid;
  grid-template-columns: auto 1fr auto; /* 좌측 아이템, 중앙 아이템, 우측 아이템 */
  width: 100%;
  height: 75px;
  background-color: #fff;
  position: relative;
  align-items: center; /* 세로 중앙 정렬 */
  padding: 0 0 0 5px;
}

.side_menu_top .mobile-header-logo {
  grid-column: 1; /* 좌측에 배치 */
}

.side_menu_top div:nth-child(2) {
  grid-column: 2; /* 중앙에 배치 */
}

.side_menu_top .close_button {
  grid-column: 3; /* 우측에 배치 */
}

.close_button {
  @include background_set(20px, 20px, contain);
  background-image: url('../assets/images/mobile_header_arrow.png');
  position: absolute;
  top: 57%;
  right: 30px;
  transform: translateY(-50%);
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  color: #000;
  z-index: 1000; /* 닫기 버튼이 화면 위로 올라오도록 z-index 설정 */
}
.close_button:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.menu_section {
  color: #707070;
  span {
    padding-left: 35px;
  }
  .section1-s {
    width: 100%;
    display: block;
    padding-left: 16.3px;
  }
  .section2-s {
    width: 100%;
    display: block;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 16.3px;
  }
  .home_icon {
    @include background_set(22px, 22px, contain);
    background-image: url('../assets/images/aside_home_gray.png');
    // display: inline-block;
    vertical-align: middle;
  }

  .logout_icon {
    @include background_set(22px, 22px, contain);
    background-image: url('../assets/images/aside_logout_gray.png');
    // display: inline-block;
    vertical-align: middle;
  }
}
.menu_section ul {
  padding: 10px;
}

.menu_section ul li {
  list-style: none; /* 기본 리스트 스타일 제거 */
  margin-bottom: 10px; /* 각 항목 아래 여백 추가 */
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 12px;
  background-color: #f8f8f8; /* 회색 배경색 */
  position: relative; /* 아이콘 위치를 조정하기 위해 */
}

.menu_section ul li:before {
  content: ''; /* 가상 요소를 이용한 아이콘 표시 */
  display: inline-block;
  width: 20px; /* 아이콘 크기에 맞춰 조정 */
  height: 20px; /* 아이콘 크기에 맞춰 조정 */
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 10px; /* 좌측 여백 */
  top: 50%; /* 세로 중앙 정렬을 위한 위치 조정 */
  transform: translateY(-50%); /* 세로 중앙 정렬을 위한 위치 조정 */
}

.menu_section ul li.icon-1:before {
  @include background_set(17px, 18px, contain);
  background-image: url('../assets/images/sub_header_1.png'); /* 첫 번째 아이콘 이미지 경로 */
  padding-left: 10px;
}

.menu_section ul li.icon-2:before {
  @include background_set(17px, 18px, contain);
  background-image: url('../assets/images/sub_header_2.png'); /* 두 번째 아이콘 이미지 경로 */
  padding-left: 10px;
}

.menu_section ul li.icon-3:before {
  @include background_set(17px, 18px, contain);
  background-image: url('../assets/images/sub_header_3.png'); /* 세 번째 아이콘 이미지 경로 */
  padding-left: 10px;
}
.menu_section ul li.icon-4:before {
  @include background_set(15px, 16px, contain);
  background-image: url('../assets/images/sub_header_4.png'); /* 세 번째 아이콘 이미지 경로 */
  padding-left: 12px;
}

.menu_section ul li:last-child {
  margin-bottom: 0; /* 마지막 항목의 아래 여백 제거 */
}

.logo_image {
  max-width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.menu_section:last-child {
  position: absolute;
  bottom: 20px;
  left: 5px;
}

.mobile-header-logo {
  @include background_set(60px, 60px, contain);
  background-image: url('../assets/images/logo_img.png');
}
.mobile-txt {
  font-size: 16px;
  padding-left: 7px;
  color: #707070;
}
/* 모바일 화면에서는 사이드 메뉴 토글 버튼 숨김 */
@media (min-width: 1000px) {
  .side_menu_open {
    display: none;
  }

  /* PC 화면에서는 초기에 사이드 메뉴 숨김 */
  .side_menu {
    display: none;
  }
}

.loading-wrap {
  width: 100%;
  height: 100%;
  position: relative; /* 부모 요소에 position: relative; 적용 */

  .loading-icon {
    @include background_set(20px, 20px, contain);
    background-image: url('../assets/images/loading.png');
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    height: 30%;
  }
}
.react-calendar {
  //position: absolute !important;
  z-index: 999;
}

.hospital-tab-container {
  display: flex;
  padding: 15px 25px;
  gap: 15px;
  width: 100%;
}

.hospital-tab {
  border: 1px solid #fff;
  padding: 5px 15px;
  border-radius: 18px;
  background-color: $main-color;
  color: #fff;
  cursor: pointer;
}

.no-product {
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 15px;
  font-size: 1em;
  line-height: 25px;
}

.step1-register_wrap {
  position: relative;
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;

  .step1-register-wrap {
    max-width: 95%;
    min-width: 600px;
    width: 600px;
    margin: 0 auto;

    h1 {
      text-align: center;
      padding-top: 200px;
      padding-bottom: 50px;
      font-family: 'Pretendard_l';
    }

    .input_rowt {
      width: 100%;

      &.terms {
        border-bottom: 1px solid #c6c6c6;
        padding: 20px;

        .terms_label {
          padding-left: 10px;
          vertical-align: middle;

          & span {
            font-size: 14px;
            color: #999;
          }
        }

        .register_terms_checkbox {
          width: 25px;
          height: 25px;
        }

        .terms-detail-view {
          display: inline-block;
          float: right;
          vertical-align: middle;
          margin-top: 3px;

          & span {
            color: #999;
          }
        }
      }
    }
  }
  .terms_btn_box {
    width: 100%;
    text-align: center;
    padding: 30px 0;

    .terms_register_btn {
      background-color: $main-color;
      color: #fff;
      width: 100%;
      border: none;
      border-radius: 12px;
      font-family: 'Pretendard_bb';
      cursor: pointer;
      padding: 15px 0;
      font-size: 18px;
    }
  }
}

/* 약관관련 모달 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  .modal-content {
    background: white;
    border-radius: 17px;
    max-width: 800px;
    width: 100%;
    max-height: 700px;
    overflow: hidden; /* 스크롤 없이 고정 */
    position: relative;
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    line-height: 1.4;

    &.open {
      transform: translateY(0);
      opacity: 1;
    }

    .close-btn-wrap {
      height: 40px;
      .modal-close {
        position: absolute;
        top: 6px;
        right: 20px;
        background: transparent;
        border: none;
        font-size: 25px;
        cursor: pointer;
        z-index: 1001; /* 모달 콘텐츠보다 위에 위치 */
        color: #000;
      }
    }

    .modal-body {
      max-height: 600px; /* 모달 본문에 대한 최대 높이 설정 */
      overflow-y: auto; /* 스크롤 생성 */
      padding: 40px; /* close 버튼 공간 확보 */
    }
  }
}

.reserv_collabo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  letter-spacing: 1.2px; /* 가독성을 위한 적당한 자간 */
  width: 100%;

  .collabo-left-box,
  .collabo-right-box {
    font-size: 1.3em; /* 고급스러운 느낌을 위해 글씨 크기 약간 조정 */
    font-family: 'Pretendard_b', sans-serif; /* 더 세련된 폰트 */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%; /* 원 모양을 유지하면서도 공간을 더 확보 */
    height: 40%;
    border-radius: 22px; /* 원 모양 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 부드러운 그림자 */
    padding: 15px; /* 원 안에서 여백을 조금 더 추가 */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    background-color: #ffffff; /* 배경 색상을 고급스럽게 */
    color: #333; /* 텍스트 색상은 어두운 색으로 안정감 줌 */
  }

  .collabo-left-box {
    background-color: #f0f9ff; /* 연한 파란색 */
    color: #0076d3; /* 좀 더 차분한 파란색 */
  }

  .collabo-right-box {
    background-color: #f8f0ff; /* 연한 보라색 */
    color: #6a3eb8; /* 진한 보라색 */
  }

  .collabo-center {
    font-size: 1.2em;
    font-family: 'Pretendard_m', sans-serif; /* 세련된 글꼴 */
    color: #444; /* 약간 밝은 어두운 색상 */
    font-weight: 600;
    padding: 0 20px;
  }

  /* hover 효과 */
  .collabo-left-box:hover,
  .collabo-right-box:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* 부드럽고 강하지 않은 그림자 효과 */
  }
}

/* 접속통계 */
.statistics-wrap {
  padding: 30px;

  .statistics-section1 {
    border: 1px solid #c6c6c6;
    background-color: #fffde1;
    padding: 12px;
    line-height: 1.5;
    font-size: 1em;

    .cblue {
      color: #0033cc;
    }

    .cred {
      color: #df2e2e;
    }
  }
  .statistics-section2 {
    border: 1px solid #c6c6c6;
    padding: 12px;
    margin-top: 10px;

    .live-data {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;

      .clock-wrap {
        font-size: 1.2em;
        color: #b92e24;
      }
      .data-wrap {
        .c1 {
          color: grey;
          padding-right: 10px;
        }
        .c2 {
          color: #0033cc;
        }
      }
    }
  }
  .statistics-section3 {
    border: 1px solid #c6c6c6;
    margin-top: 10px;
    padding: 12px;
    margin-bottom: 100px;

    .data-grid-wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* 3열 레이아웃 */
      gap: 20px; /* 카드 간 간격 */
      padding: 20px; /* 내부 여백 */
    }
  }
}

.filter-buttons {
  padding-bottom: 15px;
  border-bottom: 2px solid #c6c6c6;
}

.filter-buttons button {
  padding: 7px 12px;
  font-size: 15px;
  border-radius: 18px;
  border: none;
  border: 1px solid #c6c6c6;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.filter-buttons button.active {
  background-color: #3eb4f3; /* 버튼 활성화 색상 (예시) */
  color: white;
}

.filter-buttons button:not(:first-child) {
  margin-left: 5px;
}

.chart-controls {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;

  .data-add {
    border: 1px solid #c6c6c6;
    background-color: #fff;
    padding: 7px 12px;
    border-radius: 18px;
    font-size: 15px;
    cursor: pointer;
  }

  .data-minus {
    border: 1px solid #c6c6c6;
    margin-left: 5px;
    background-color: #fff;
    padding: 7px 12px;
    border-radius: 18px;
    font-size: 15px;
    cursor: pointer;
  }
}

.search-date-wrap {
  display: flex;
  align-items: center;
  margin-top: 15px;

  .search-calendar-btn {
    & button {
      padding: 7px 12px;
      border-radius: 18px;
      border: 1px solid #c6c6c6;
    }
  }

  .cal-reset-btn {
    border-radius: 18px;
    border: 1px solid #fff;
    background-color: #3eb4f3;
    margin-left: 10px;
    padding: 7px 12px;
    cursor: pointer;

    & span {
      color: #fff;
    }
  }
}
