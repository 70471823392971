// 이미지
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Pretendard_m';
  word-break: keep-all;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

body,
html,
#root {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  max-height: -webkit-fill-available !important;
}

body {
  padding-top: constant(safe-area-inset-top);
  /* iOS 11.0 */
  padding-top: env(safe-area-inset-top);
  /* iOS 11+ */
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@mixin background_set($width, $height, $size) {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: $size;
  width: $width;
  height: $height;
}

// 버튼 패딩 기본 9px 20px
@mixin default_button_set($padding) {
  display: inline-block;
  padding: $padding;
  border-radius: 5px;
  color: $white;
  background-color: $main-color;
  cursor: pointer;
  box-shadow: 4px 4px 4px -4px $gray;
}

@mixin delete_button_set($padding) {
  display: inline-block;
  padding: $padding;
  border-radius: 5px;
  color: $white;
  background-color: $delete-color;
  cursor: pointer;
  box-shadow: 4px 4px 4px -4px $gray;
}

@mixin sub_button_set($padding) {
  display: inline-block;
  padding: $padding;
  border-radius: 5px;
  color: $white;
  background-color: $sub-color;
  cursor: pointer;
  box-shadow: 4px 4px 4px -4px $gray;
}

@mixin gray_button_set($padding) {
  display: inline-block;
  padding: $padding;
  border-radius: 5px;
  color: $white;
  background-color: $gray;
  cursor: pointer;
  box-shadow: 4px 4px 4px -4px $gray;
}

// 색상
$black: #000000;
$mid_black: #414141;
$white: #ffffff;
$deep-gray: #464646;
$gray: #d6d6d6;
$light-gray: #707070;
$back-gray: #dbdbdb;
$line-gray: #e8e8e8;

//$main-color: #669B2E;
$main-color: #3eb4f3;
$back-color: #f8f8f8;
$back-color_2: #e2efd6;
$sub-color_2: #2f5c00;
$sub-color: #dbebca;
$modal-color: #9999999f;

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes growRight {
  0% {
    transform: scaleX(0);
  }

  80% {
    transform: scaleX(1.1);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.w100 {
  width: 100%;
}

.sortable-handler {
  touch-action: none;
}

input {
  // appearance: none;
  // -webkit-appearance: none;
  // -webkit-border-radius: 0;
  vertical-align: middle;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  color: #000000;
  vertical-align: middle;
}

select::-ms-expand {
  display: none;
}
