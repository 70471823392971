@import 'lifeUtil.scss';

.table_box {
  box-sizing: border-box;

  &.second {
    padding-top: 50px;
  }
}

.board_list_wrap {
  &.notice {
    padding: 0 0 20px 0;
  }

  &.chart {
    height: 100%;
  }
}
.custom-wrap {
  display: block;
  margin-top: 5px;
}
.custom-wrap .custom-search {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #c9c9c9;
}
.custom-search input {
  padding: 10px 15px;
  border: none;
  font-size: 17px;
  color: #707070;
}

.table_row {
  border-bottom: 1px solid #dddddd;
  height: auto;

  &.calendar {
    overflow: initial !important;
  }

  &.active {
    height: 55px;
  }

  .table_section {
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: middle;

    //소유자 입력창
    .owner_select {
      border: 1px solid #ececec;
      padding: 0 10px;
      border-radius: 5px;
      outline: none;
      height: 30px;
      color: black;
      margin-left: 5px;
    }

    &.calendar {
      overflow: initial !important;
    }

    &.editor {
      height: 300px;
      border: 1px solid #ececec;
    }

    &.half {
      width: 50%;
    }

    &.triple {
      width: calc(100% / 3);
    }

    .board_contents_box {
      padding: 20px;
      border: 1px solid #ececec;
    }

    .table_title {
      display: inline-block;
      padding: 10px 0;
      width: 150px;
      text-align: center;
      background-color: #ececec;
      vertical-align: middle;

      &.link {
        width: 70px !important;
      }

      &.image {
        height: 150px;
      }

      &.editor {
        height: 280px;
      }

      .title_point {
        display: inline-block;
        color: red;
        vertical-align: middle;
        padding-left: 5px;
      }
    }

    .table_contents {
      display: inline-block;
      vertical-align: middle;
      padding-left: 10px;
      position: relative;

      &.calendar {
        overflow: initial !important;
      }

      &.w100 {
        width: calc(100% - 160px);
      }

      &.link {
        width: calc(100% - 70px);
      }

      &.editor {
        width: calc(100% - 150px);
        padding-left: 0;
      }

      .clock_box {
        overflow: hidden;

        .css-1bn53lx {
          height: 35px;
          outline: none;
          padding: 0 10px;

          &:focus {
            outline: none;
          }
        }

        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
          height: 35px;
          outline: none;
          padding: 0 10px;

          &:focus {
            outline: none;
          }
        }

        .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
          width: 85% !important;
        }

        .css-slyssw {
          width: 85% !important;
        }

        .css-1uvydh2 {
          padding: 0 10px !important;
        }

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
          padding: 0 0 !important;
        }

        .css-1nvf7g0 {
          height: 100%;
          width: 15%;
        }

        .css-1laqsz7-MuiInputAdornment-root {
          height: 100%;
          width: 15%;
        }
      }

      .sign_text {
        text-decoration: underline;
        text-decoration-color: $main-color;
        cursor: pointer;
      }

      .image_top_box {
        border: 1px solid #c6c6c6;
        padding: 15px;
        position: relative;

        .image_delete_box {
          position: absolute;
          z-index: 2;
          left: 200px;
          top: 4px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #ececec;
          cursor: pointer;
          text-align: center;

          &::after {
            position: absolute;
            content: '';
            width: 40px;
            height: 40px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .image_delete_btn {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }

      .delete_input {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
      }

      .modal_calendar {
        position: absolute;
        z-index: 99;
      }

      .chk_input {
        display: inline-block;
        width: 50px;
        outline: none;
        border: 1px solid #ececec;
        height: 20px;
        border-radius: 5px;
        margin-top: 2px;
        vertical-align: middle;
      }

      .sla {
        display: inline-block;
        vertical-align: middle;
        margin-left: -8px;
        /* 체크박스와 레이블 사이의 간격 조정 */
      }

      .table_input {
        width: 350px;
        outline: none;
        border: 1px solid #ececec;
        height: 35px;
        border-radius: 5px;
        padding: 0 10px;

        &.num {
          width: calc((100% - 622px) / 3);
        }

        &.my_num {
          width: calc((100% - 50px) / 3);
        }

        &.half {
          width: calc((100% - 90px) / 2);
        }

        &.triple {
          width: calc((100% - 120px) / 3);
        }

        &.phone {
          width: calc((100% - 622px) / 3);
        }

        &.w100 {
          width: 100%;
        }
      }

      .table_textarea {
        outline: none;
        resize: none;
        width: 100%;
        border: 1px solid #ececec;
        height: auto;
        min-height: 143px;
        margin-top: 4px;
        padding: 10px;
      }

      .table_radio {
        display: inline-block;
        vertical-align: middle;
        padding-right: 20px;
      }

      .table_inner_text {
        display: inline-block;
        padding-right: 20px;
        vertical-align: middle;
        font-size: 15px;

        .inner_join_text {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .table_more_btn {
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
        background-color: #999999;
        color: #fff;
        border-radius: 5px;
        padding: 5px 10px;
      }

      .table_inner_btn {
        display: inline-block;
        vertical-align: middle;
        width: 70px;
        font-size: 15px;
        background-color: $gray;
        height: 30px;
        text-align: center;
        cursor: pointer;
        border-radius: 10px;
        border: 1px solid #707070;
        padding: 5px 0;

        &.sign {
          width: 100px;
        }
      }

      .table_select {
        width: 320px;
        outline: none;
        border: 1px solid #ececec;
        height: 35px;
        border-radius: 5px;
        padding: 0 10px;
      }

      .table_scroll_box {
        height: 150px;
        overflow-y: scroll;
      }

      .table_radio_box {
        .table_radio {
          margin-left: 20px;

          &:first-child {
            margin-left: 0;
          }
        }

        &.inline_block {
          display: inline-block;
        }
      }

      .sub_input_box {
        display: inline-block;
        margin-left: 20px;
      }

      .table_image_box {
        width: 250px;
        height: 130px;
        border: 2px dotted #dddddd;
        border-radius: 10px;
        position: relative;

        .file_input {
          display: none;
        }

        .image_label {
          width: 100%;
          height: 100%;
          display: inline-block;
          cursor: pointer;

          .btn_upload {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url('../assets/images/gray_cam.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 50%;
            height: 50%;
          }
        }

        .table_img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      .calendar_box {
        display: inline-block;
        text-align: center;
        border: 1px solid #ececec;
        border-radius: 20px;
        vertical-align: middle;
        padding: 6px 9px;
        color: #000;
        font-size: 15px;
        font-weight: 500;
        position: relative;
        width: 250px;

        .sed_calendar {
          display: none;
          position: absolute;
          top: 40px;
          left: -4px;
          border-radius: 10px;
          border: 1px solid #ececec;
        }

        &.show {
          .sed_calendar {
            display: block;
            z-index: 2;
          }
        }

        .calendar_point {
          display: inline-block;
          padding: 0 20px;
        }
      }
    }
  }
}

.edit_table {
  &.hidden {
    display: none;
  }
}

.log_wrap {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  .log_table {
    border-top: 2px solid #dddddd;
    border-bottom: 2px solid #dddddd;

    .log_header {
      background-color: #dddddd;
      border-bottom: 2px solid #dddddd;
      display: flex;

      .header_section {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 400px);
        height: 40px;
        font-weight: bold;

        &.triple {
          width: 300px;
        }

        &.short {
          width: 400px;
        }
      }
    }

    .log_row {
      height: 80px;
      border-bottom: 1px solid #dddddd;
      display: flex;

      &:last-child {
        border-bottom: none;
      }

      .log_section {
        height: 100%;
        border-right: 1px solid #dddddd;
        vertical-align: top;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: calc(100% - 400px);

        &:last-child {
          border-right: none;
        }

        &.triple {
          width: 300px;
          background-color: #dddddd;
          border-bottom: 1px solid #fff;
          line-height: 40px;
          flex-direction: column;

          .small_font {
            width: 100%;
            font-size: 13px;
            line-height: 19px;
          }
        }

        &.short {
          width: 400px;
        }
      }
    }
  }
}

.setting_wrap {
  border-top: 2px solid $main-color;
  border-bottom: 2px solid $main-color;

  .setting_section {
    padding: 50px 20px 0;

    .setting_title {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 20px;
    }

    .setting_container {
      .setting_row {
        display: inline-block;
        width: calc((100% - 6px) / 3);
        border: 1px solid #dddddd;

        .row_title {
          display: inline-block;
          width: 220px;
          text-align: center;
          background-color: #ececec;
          vertical-align: middle;
          padding: 15px 0;
          font-weight: 700;
        }

        .row_input_box {
          display: inline-block;
          width: calc(100% - 221px);
          padding: 0 20px;
          box-sizing: border-box;

          .row_input {
            width: 90%;
            outline: none;
            border: 1px solid #ececec;
            height: 35px;
            border-radius: 5px;
            padding: 0 10px;
          }
        }
      }
    }
  }
}

.setting_wrap {
  .setting_btn_box {
    padding: 50px 0;
    text-align: center;

    .setting_btn {
      width: 150px;
      background-color: $main-color;
      color: #fff;
    }
  }
}

/*********************/
/* 문의게시판 */
/*********************/

.title_area {
  width: 100%;
  height: 100px;

  .title_box {
    display: inline-block;
    width: 50%;

    .title {
      display: inline-block;
      font-size: 25px;
      font-weight: 700;
      position: relative;
      color: #000;
      letter-spacing: 1px;

      &::after {
        content: '';
        position: absolute;
        width: 60%;
        height: 1px;
        border-bottom: 2px solid #5387cc;
        top: 45px;
        left: 0;
      }
    }
  }
}

.list_area {
  width: 100%;
  position: relative;

  &.chart {
    height: 100%;
  }

  &.reserv {
    // padding-top: 30px;

    .search_box {
      padding: 0 20px 10px 20px;
      border: none;
      box-shadow: 0px 10px 20px #6f6f6f1d;
      //border-radius: 20px;
      margin-bottom: 20px;
      position: relative;

      .search_select {
        margin-right: 0;

        .list_select {
          margin-right: 9px;
        }
      }

      .search_input {
        .list_search {
          background-color: #f4f4f4;
          color: #5f5f5f;
          padding: 10px 50px 10px 30px;
          margin-right: 9px;

          &.notice {
            // position: absolute !important;
            // right: 75px !important;
            // top: 13px !important;
          }
          &:first-child {
            position: absolute;
            right: 85px;
            bottom: 10px;

            &::after {
              position: absolute;
              content: '';
              right: 25px;
              top: 50%;
              transform: translate(0, -50%);
              @include background-set(18px, 20px, contain);
              background-image: url('../assets/images/search_icon.png');
            }
          }

          &.reset_btn {
            background-color: #f4f4f4 !important;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}

.submit_btn {
  padding: 10px 25px;
  border-radius: 5px;
  color: #ffffff;
  background-color: $main-color;
  cursor: pointer;
  box-shadow: 4px 4px 4px -4px #999999;
  display: inline-block;
  font-size: 16px;
}

.list_select_area {
  display: inline-block;

  &.chart {
    display: block;
    padding-top: 10px;
  }

  &.right {
    float: right;
  }
}

.search_right {
  padding: 10px 20px;
  border-radius: 10px;
  color: #ffffff;
  background-color: $main-color;
  cursor: pointer;
  float: right;
}

.search_box {
  background-color: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid #c9c9c9;

  &.tab {
    border-radius: 0 15px 15px;
  }

  .title_btn {
    text-align: center;
    padding: 10px 20px;
    border-radius: 10px;
    color: #ffffff;
    background-color: $main-color;
    cursor: pointer;
    float: right;
    margin-top: 3.5px;
  }

  .total_btn {
    padding: 0px 10px;
    float: right;
  }

  .total_btn .total_box {
    border-radius: 5px;
    border: 1px solid #c6c6c6;
    padding: 6px;
  }

  .search_input_box {
    display: inline-block;
    width: 50%;
  }

  .search_select.xs1 {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    position: relative;
  }

  .list_select_user {
    padding: 10px 15px;
    border: 1px solid #c9c9c9;
    border-radius: 10px;
    margin-right: 13px;
    outline: 0 none;
    color: #707070;
    font-size: 15px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 2.5em; /* 여유 공간 만들기 */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="black" d="M6 8l4 4 4-4z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 0.5em top 50%, 0 0;
    background-size: 1.5em auto, 100%; /* 삼각형 이미지 크기 조정 */

    &:last-child {
      margin-left: 0;
    }

    option {
      border-radius: 5px;
    }
  }

  .search_select {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;

    .search_input_container {
      display: inline-block;

      &.product {
        padding-top: 0;
      }
    }

    .list_select {
      padding: 10px 15px;
      border: 1px solid #c9c9c9;
      border-radius: 12px;
      margin-right: 7px;
      outline: 0 none;
      color: #707070;
      font-size: 17px;
      vertical-align: middle;

      &:last-child {
        margin-left: 0;
      }

      option {
        border-radius: 5px;
      }
    }
  }

  .search_input {
    display: inline-block;
    vertical-align: middle;

    .chart_calendar {
      position: absolute;
      top: 50px;
      z-index: 3;
    }

    .list_input {
      display: inline-block;
      padding: 10px 15px;
      border: 1px solid #c9c9c9;
      //border-radius: 10px;
      margin-left: 5px;
      outline: 0 none;
      color: #707070;
      font-size: 17px;
      margin-right: 10px;
      vertical-align: middle;

      &.chart {
        margin-left: 0;
      }
    }

    .list_search {
      display: inline-block;
      vertical-align: middle;
      display: inline-block;
      padding: 10px 20px;
      border-radius: 12px;
      color: #ffffff;
      background-color: $main-color;
      cursor: pointer;
      font-size: 17px;
      margin-right: 7px;

      &.reset_btn {
        background-color: #9c9ea0 !important;
      }
    }
  }

  .page_num_box {
    width: 50%;
    display: inline-block;
    text-align: right;

    .page_btn {
      display: inline-block;
      padding: 5px 20px;
      border-radius: 5px;
      color: #ffffff;
      background-color: #fa3737cc;
      cursor: pointer;
      box-shadow: 4px 4px 4px -4px #999999;
      margin-right: 10px;
    }
  }
}

.table_box {
  width: 100%;

  &.notice {
    padding: 0 20px 0 20px;
  }

  &.list {
    height: 750px;
    width: 100% !important;
    padding: 20px;
  }

  &.home {
    padding: 30px 50px;
  }

  &.tab_list {
    height: 750px;
    width: 100% !important;
    padding: 0 20px 20px;
  }

  .chart_box {
    // padding: 20px;
    background-color: #fff;
    margin: 0 20px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
  }

  .list_inner_btn {
    background-color: $main-color;
    color: #fff;
    padding: 3px 15px;
    letter-spacing: 5px;
    cursor: pointer;
    border-radius: 3px;
    position: relative;
    z-index: 9;
  }

  .list_table {
    width: 100%;
    border-top: 2px solid $main-color;
    border-bottom: 2px solid $main-color;
    border-collapse: collapse;

    .table_header {
      height: 40px;
      color: #000000;
      letter-spacing: 1px;
      background-color: #ebeaea67;
      border-bottom: 2px solid $main-color;
      font-size: 15px;

      .table_header_col {
        border-bottom: 1px solid #dddddd;
      }
    }

    .table_body {
      text-align: center;
      height: 45px;
      width: 100%;
      font-size: 13px;

      .table_col {
        border: 1px solid #dddddd;
        padding: 10px;

        .table_option_box {
          .option_btn {
            display: inline-block;
            background-color: $main-color;
            color: #ffff;
            padding: 5px 20px;
            border-radius: 5px;
            cursor: pointer;
            margin-right: 20px;

            &.del {
              background-color: $light-gray;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.table_col .clip_icon {
  width: 15px;
  height: 15px;
  margin: 0 auto;
  background-image: url('../assets/images/clip_icon.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
}

.table_box .list_table .table_body .table_col {
  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }

  &.pointer {
    cursor: pointer;
    text-decoration: underline;
    color: #5387cc;
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.short_col {
  width: 12%;
}

.short_col_20 {
  max-width: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.short_col_30 {
  max-width: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pagination_box {
  padding: 20px;
  text-align: center;

  button {
    width: 40px;
    height: 40px;
    margin-left: 5px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
    box-shadow: 4px 4px 4px -4px #999999;
    font-size: 15px;
    font-weight: bold;

    &.active {
      font-weight: 700;
      background-color: $main-color;
      color: #fff;
    }
  }
}

.board_editor {
  width: calc(100% - 10px);
  display: inline-block;
}

.add_btn {
  margin-top: 2px;
  display: inline-block;
  vertical-align: middle;
  padding: 5px 20px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #669b2e;
  cursor: pointer;
  box-shadow: 4px 4px 4px -4px #999999;
}

.modal_wrap {
  background-color: #9999999f;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
  z-index: 5;

  &.product {
    z-index: 25;
  }

  &.reserv {
    .modal_back {
      box-shadow: 0px 10px 20px #00000029;
      border-radius: 30px;
      width: 40%;
      min-width: 500px;

      .modal_box {
        padding-top: 80px;

        .modal_text {
          color: #414141;
          font-size: 30px;
          text-align: center;
          padding-bottom: 60px;
        }

        .modal_footer_box {
          display: flex;
          justify-content: center;
          .modal_btn {
            color: #6f7070;
            background-color: #f4f4f4;
            border-radius: 10px;
            padding: 10px 37px;
            font-size: 20px;

            &.close {
              background-color: #fff;
              border: 1px solid #eaeaea;
            }
          }
        }
      }
    }
  }

  .modal_back {
    width: 60%;
    max-height: 90%;
    overflow-y: scroll;
    background-color: #ffffff;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.pw-search {
      width: 40%;
    }

    &.product {
      width: 95%;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .modal_box {
      padding: 20px;

      .modal_title_box {
        border-bottom: 1px solid #dddddd;
        padding-bottom: 20px;

        .modal_title {
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 1px;
          display: inline-block;
        }

        .modal_text {
          display: inline-block;
          font-size: 23px;
          color: #017aff;
          padding-right: 15px;
          padding-top: 6px;
          font-family: 'Pretendard_b';
          vertical-align: middle;

          &.title {
            color: #414141;
            font-size: 25px;
          }

          &.sub {
            color: #414141;
            font-size: 15px;
            font-family: 'Pretendard_m';
          }
        }

        .modal_close_btn {
          display: inline-block;
          float: right;
          padding-right: 10px;
          font-size: 18px;
          cursor: pointer;
          vertical-align: top;

          &::after {
            content: '';
            width: 50px;
            height: 50px;
            position: absolute;
            top: 9px;
            right: 9px;
          }
        }
      }

      .down_back {
        @include background_set(100%, 1200px, contain);
        background-image: url('../assets/images/sign_background.png');
        position: relative;

        .sign_img_container {
          position: absolute;
          width: 58%;
          height: 79px;
          top: 65%;
          left: 31%;

          .sign_img_box {
            width: 180px;
            height: 100%;
            display: inline-block;
            vertical-align: middle;

            &:last-child {
              margin-left: 190px;
            }

            .sign_img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .modal_content_box {
        padding: 10px 10px;
        .content_link {
          display: flex;
          justify-content: start;
          flex-direction: column;
          padding: 30px 0;

          .naver_blog_img {
            display: inline-block;
            background-image: url('../assets/images/naver_blog.png');
            background-repeat: no-repeat;
            background-size: contain;
            width: 25px;
            height: 25px;
            vertical-align: middle;
            margin-right: 7px;
          }
          .instagram_img {
            display: inline-block;
            background-image: url('../assets/images/instagram.png');
            background-repeat: no-repeat;
            background-size: contain;
            width: 25px;
            height: 25px;
            vertical-align: middle;
            margin-right: 7px;
          }

          .last-p {
            margin-bottom: 45px;
          }

          & h2 {
            display: flex;
            align-items: center;
            letter-spacing: 1.3px;
            font-size: 1.3rem;
          }

          & h2:not(:first-child) {
            padding-top: 40px;
          }

          & p {
            padding-top: 10px;
          }
        }
        .content_img {
          @include background_set(100%, 205vw !important, cover);
          // background-image: url("../img/product_detail_cha5.png");

          &.vip {
            height: 205vw;
            background-image: url('../assets/images/product_detail_vip.png');
          }

          &.smart {
            height: 205vw;
            background-image: url('../assets/images/product_detail_smart.png');
          }

          &.premium {
            height: 205vw;
            background-image: url('../assets/images/product_detail_premium.png');
          }

          &.nobless {
            height: 205vw;
            background-image: url('../assets/images/product_detail_nobless.png');
          }

          &.cha1 {
            height: 150vw !important;
            background-image: url('../assets/images/product_detail_cha1.png');
          }

          &.cha2 {
            height: 150vw !important;
            background-image: url('../assets/images/product_detail_cha2.png');
          }

          &.cha3 {
            height: 150vw !important;
            background-image: url('../assets/images/product_detail_cha3.png');
          }

          &.cha4 {
            height: 150vw !important;
            background-image: url('../assets/images/product_detail_cha4.png');
          }

          &.cha5 {
            height: 150vw !important;
            background-image: url('../assets/images/product_detail_cha5.png');
          }
        }

        .modal_input_box {
          display: inline-block;
          width: 50%;
        }
      }
    }

    .modal_footer_box {
      text-align: center;
      padding: 10px 0;

      &.product {
        padding-top: 50px;
      }

      .modal_btn {
        display: inline-block;
        padding: 9px 20px;
        border-radius: 5px;
        color: #ffffff;
        background-color: $main-color;
        cursor: pointer;
        box-shadow: 4px 4px 4px -4px #999999;
        font-size: 11px;

        &.margins:first-child {
          margin-right: 10px;
        }

        &.close {
          display: inline-block;
          padding: 9px 20px;
          border-radius: 5px;
          color: #ffffff;
          background-color: #999999;
          cursor: pointer;
          box-shadow: 4px 4px 4px -4px #999999;
          margin-left: 10px;
        }
      }
    }
  }
}

/*********************/
/* 메인 */
/*********************/

.main-wrap {
  margin-left: 250px;
  width: calc(100% - 250px);

  .content-wrap {
    padding: 55px 55px 100px;

    .page-dir {
      font-size: 30px;
      padding: 10px 0 50px 0;
      letter-spacing: 2.5px;
      font-family: 'AppleSD_EB';
    }
  }
}

.table_answer_row {
  display: none;
  width: 100%;

  .answer-box {
    width: 95%;
    height: 150px;
    padding: 20px;
    text-align: left;
  }
}

.answer-box.read-only {
  background-color: #ebeaea67;
}

.table_answer_row td {
  &:first-child {
    font-size: 13px;
    font-weight: 800;
  }

  &:nth-child(2) {
    font-size: 16px;
  }

  &:first-child {
    background-color: #ebeaea67;
  }
}

.active_row {
  display: table-row;
}

.table_answer_row {
  .qna-edit-btn {
    padding: 7px 20px;
    border-radius: 5px;
    color: #ffffff;
    background-color: #cda361;
    cursor: pointer;
    box-shadow: 4px 4px 4px -4px #999999;
    margin-right: 5px;
  }

  .qna-del-btn {
    padding: 7px 20px;
    border-radius: 5px;
    color: #ffffff;
    background-color: #cda361;
    cursor: pointer;
    box-shadow: 4px 4px 4px -4px #999999;
  }
}

/*********************/
/* 여행후기 게시판 */
/*********************/

.empty_image {
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/gray_cam.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
}

.review_img_box {
  margin-right: 20px;
  width: calc((100% - 600px) / 5);
  height: 200px;
  display: inline-block;
  padding: 25px;
  cursor: pointer;

  .review-box {
    width: 100%;
    border-top: 2px solid #cda361;
    border-bottom: 2px solid #cda361;
    border-collapse: collapse;
  }

  .review_img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: contain;

    /* border: 1px solid #c6c6c6; */

    &:hover {
      transition: transform 0.3s, box-shadow 0.3s;
      transform: scale(1.05);

      /* 1.1 배 확대 (조절 가능) */
    }
  }

  .review_text {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-size: 15px;
    font-weight: 700;
  }

  .review_text_box {
    padding-top: 5px;

    .date {
      text-align: right;
      padding-top: 5px;
      margin-bottom: 20px;
    }
  }
}

/*********************/
/* 여행 TIP 게시판 */
/*********************/

.tip_img_box {
  margin-right: 20px;
  width: calc((100% - 600px) / 5);
  height: 200px;
  display: inline-block;
  padding: 25px;
  cursor: pointer;

  .tip-box {
    width: 100%;
    border-top: 2px solid #cda361;
    border-bottom: 2px solid #cda361;
    border-collapse: collapse;
  }

  .tip_img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: contain;

    /* border: 1px solid #c6c6c6; */

    &:hover {
      transition: transform 0.3s, box-shadow 0.3s;
      transform: scale(1.05);

      /* 1.1 배 확대 (조절 가능) */
    }
  }

  .tip_text {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-size: 15px;
    font-weight: 700;
  }

  .tip_text_box {
    padding-top: 5px;

    .date {
      text-align: right;
      padding-top: 5px;
      margin-bottom: 20px;
    }
  }
}

.result-zero {
  color: #000000;
  border-bottom: 5px solid $main-color;
  padding: 150px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

/*********************/
/* 이벤트 게시판 */
/*********************/

.event-wrap {
  width: 100%;
  height: 100%;

  .loading {
    background-image: url('../assets/images/commingsoon.gif');
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 80vh;
  }
}

.settings-wrap {
  padding: 20px;

  .sub-wrap {
    h4 {
      border-left: solid 5px #ccc;
      padding-left: 10px;
      margin: 10px 0 8px 0;
    }

    background: #efefef;
    padding: 16px 30px 15px 20px;
    border: solid 1px #ccc;
    text-align: left;
    margin-bottom: 10px;
    overflow: hidden;

    input {
      padding: 5px;
    }
  }
}

/*********************/
/* 메인 */
/*********************/

.main-wrap {
  margin-left: 250px;
  width: calc(100% - 250px);

  .content-wrap {
    padding: 55px 55px 100px;

    .page-dir {
      font-size: 30px;
      padding: 10px 0 50px 0;
      letter-spacing: 2.5px;
      font-family: 'AppleSD_EB';
    }
  }
}

.table_answer_row {
  display: none;
  width: 100%;

  .answer-box {
    width: 95%;
    height: 150px;
    padding: 20px;
    text-align: left;
  }
}

.answer-box.read-only {
  background-color: #ebeaea67;
}

.table_answer_row td {
  &:first-child {
    font-size: 13px;
    font-weight: 800;
  }

  &:nth-child(2) {
    font-size: 16px;
  }

  &:first-child {
    background-color: #ebeaea67;
  }
}

.active_row {
  display: table-row;
}

.table_answer_row {
  .qna-edit-btn {
    padding: 7px 20px;
    border-radius: 5px;
    color: #ffffff;
    background-color: #cda361;
    cursor: pointer;
    box-shadow: 4px 4px 4px -4px #999999;
    margin-right: 5px;
  }

  .qna-del-btn {
    padding: 7px 20px;
    border-radius: 5px;
    color: #ffffff;
    background-color: #cda361;
    cursor: pointer;
    box-shadow: 4px 4px 4px -4px #999999;
  }
}

/*********************/
/* 여행후기 게시판 */
/*********************/

.empty_image {
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/gray_cam.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
}

.review_img_box {
  margin-right: 20px;
  width: calc((100% - 600px) / 5);
  height: 200px;
  display: inline-block;
  padding: 25px;
  cursor: pointer;

  .review-box {
    width: 100%;
    border-top: 2px solid #cda361;
    border-bottom: 2px solid #cda361;
    border-collapse: collapse;
  }

  .review_img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: contain;

    /* border: 1px solid #c6c6c6; */

    &:hover {
      transition: transform 0.3s, box-shadow 0.3s;
      transform: scale(1.05);

      /* 1.1 배 확대 (조절 가능) */
    }
  }

  .review_text {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-size: 15px;
    font-weight: 700;
  }

  .review_text_box {
    padding-top: 5px;

    .date {
      text-align: right;
      padding-top: 5px;
      margin-bottom: 20px;
    }
  }
}

/*********************/
/* 여행 TIP 게시판 */
/*********************/

.tip_img_box {
  margin-right: 20px;
  width: calc((100% - 600px) / 5);
  height: 200px;
  display: inline-block;
  padding: 25px;
  cursor: pointer;

  .tip-box {
    width: 100%;
    border-top: 2px solid #cda361;
    border-bottom: 2px solid #cda361;
    border-collapse: collapse;
  }

  .tip_img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: contain;

    /* border: 1px solid #c6c6c6; */

    &:hover {
      transition: transform 0.3s, box-shadow 0.3s;
      transform: scale(1.05);

      /* 1.1 배 확대 (조절 가능) */
    }
  }

  .tip_text {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-size: 15px;
    font-weight: 700;
  }

  .tip_text_box {
    padding-top: 5px;

    .date {
      text-align: right;
      padding-top: 5px;
      margin-bottom: 20px;
    }
  }
}

.result-zero {
  color: #000000;
  border-bottom: 5px solid $main-color;
  padding: 150px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

/*********************/
/* 이벤트 게시판 */
/*********************/

.event-wrap {
  width: 100%;
  height: 100%;

  .loading {
    background-image: url('../assets/images/commingsoon.gif');
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 80vh;
  }
}

.tab_area {
  width: 100%;
  padding: 20px 20px 0 20px;
  margin-bottom: -4px !important;

  &.sub {
    padding: 20px 20px 0 20px !important;
  }

  .tab_back {
    height: 100%;

    &.customer {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 70px);
    }
  }

  .print_btn {
    display: inline-block;
    vertical-align: middle;
    padding: 7px 20px;
    border-radius: 10px;
    color: #ffffff;
    background-color: $main-color;
    cursor: pointer;
    font-size: 17px;
    margin-bottom: 10px;
  }

  .tab_menu {
    display: inline-block;
    border: 1px solid #c6c6c6;
    padding: 10px 20px;
    width: 200px;
    text-align: center;
    border-right: none;
    border-bottom: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    cursor: pointer;

    &.active {
      background-color: $main-color;
      color: #ffffff;
      font-weight: bold;
    }

    &:last-child {
      border-right: 1px solid #c6c6c6;
    }
  }
}

.settings-wrap {
  padding: 20px;

  .sub-wrap {
    h4 {
      border-left: solid 5px #ccc;
      padding-left: 10px;
      margin: 10px 0 8px 0;
    }

    background: #efefef;
    padding: 16px 30px 15px 20px;
    border: solid 1px #ccc;
    text-align: left;
    margin-bottom: 10px;
    overflow: hidden;

    input {
      padding: 5px;
    }
  }
}

.table_header {
  border-color: #ededed !important;
  background-color: #fff;
}

.table_cell {
  text-align: center !important;
  border-color: #ededed !important;
  background-color: #fff;
}

.MuiDataGrid-withBorderColor {
  border-color: #ededed !important;
}

.css-az8st9-MuiDataGrid-root {
  align-items: center !important;
  border-style: none !important;
}

// .css-az8st9-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
//   justify-content: center !important;
//   -webkit-box-pack: center !important;
// }

.css-de9k3v-MuiDataGrid-selectedRowCount {
  display: none !important;
}

.css-204u17-MuiDataGrid-main {
  width: 100% !important;
  // box-shadow: 0px 10px 20px #0000001d !important;
  border-radius: 10px !important;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-size: 15px !important;
  color: #606060 !important;
  font-family: 'Pretendard_b' !important;
}

.css-az8st9-MuiDataGrid-root {
  .MuiDataGrid-cellContent {
    color: #606060 !important;
    font-size: 15px !important;
    font-family: 'Pretendard_l';
  }
}

.css-rtrcn9-MuiTablePagination-root {
  color: #868686 !important;
}

.css-wop1k0-MuiDataGrid-footerContainer {
  justify-content: center !important;
}

.MuiDataGrid-cell {
  -webkit-box-pack: center !important;
  justify-content: center !important;
  -webkit-justify-content: center !important;
  align-items: center !important;
}

// 상세
.detail_wrap {
  .detail_back {
    .detail_title_box {
      font-size: 25px;
      font-family: 'Pretendard_bb';
      border-bottom: 1px solid #c9c9c9;
      padding: 10px 50px;
      background-color: #fff;
    }

    .detail_container {
      width: 100%;
      padding-bottom: 50px;

      .container_title_box {
        padding: 10px 50px;

        .detail_title {
          &.sub {
            font-size: 20px;
            font-family: 'Pretendard_b';
            display: inline-block;
            vertical-align: middle;
            padding-right: 30px;
          }
        }

        .title_btn {
          vertical-align: middle;
          display: inline-block;
          background-color: $main-color;
          color: #fff;
          padding: 5px 20px;
          border-radius: 3px;
        }
      }

      .detail_table_box {
        &.top {
          .MuiDataGrid-footerContainer {
            display: none !important;
          }
        }
      }
    }
  }
}

.table_inner_btn {
  background-color: $main-color;
  color: #fff;
  padding: 3px 20px;
  letter-spacing: 5px;
  cursor: pointer;
  border-radius: 3px;

  &.reserv {
    letter-spacing: 0;
    color: #727272;
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 5px 35px 5px 25px;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      right: 15px;
      top: 50%;
      transform: translate(0, -50%);
      @include background-set(12px, 8px, contain);
      background-image: url('../assets/images/check_icon.png');
    }
  }
}

#chart {
  padding: 20px;
  background-color: #fff;
  margin: 0 20px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
}

// .css-204u17-MuiDataGrid-main {
//   width: 100% !important;
//   box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1137254902) !important;
//   border-radius: 0px !important;
//   border-bottom-left-radius: 10px !important;
//   border-bottom-right-radius: 10px !important;
// }

.disease-info {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #333;

  .title {
    text-align: center;
    font-size: 24px;
    color: #0078d7;
    margin-bottom: 20px;
  }

  .section {
    margin-bottom: 40px;
    padding: 15px;
    border-radius: 8px;
    background: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .section-header {
      font-size: 18px;
      color: #0078d7;
      margin-bottom: 10px;
      border-bottom: 2px solid #0078d7;
      padding-bottom: 5px;
    }

    .sub-header {
      font-size: 16px;
      color: #333;
      margin: 10px 0;
    }

    .list {
      list-style-type: disc;
      padding-left: 20px;

      li {
        margin-bottom: 5px;
      }
    }
  }

  .note {
    font-size: 12px;
    color: #555;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

.redsec {
  color: red;
  vertical-align: middle;
  font-weight: 550;
}

.react-calendar.modal_calendar.hospital {
  position: absolute;
  top: 7%;
  border-radius: 12px;
}
