/* APPLE */
@font-face {
  font-family: 'AppleSD_R';
  src: url('../assets/fonts/AppleSDGothicNeoR.ttf');
}

/* pretendard */
@font-face {
  font-family: 'Pretendard_m';
  src: url('../assets/fonts/Pretendard-Medium.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Pretendard_b';
  src: url('../assets/fonts/Pretendard-SemiBold.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'Pretendard_bb';
  src: url('../assets/fonts/Pretendard-Bold.otf');
  font-weight: 700;
}
@font-face {
  font-family: 'Pretendard_bbb';
  src: url('../assets/fonts/Pretendard-ExtraBold.otf');
  font-weight: 900;
}
@font-face {
  font-family: 'Pretendard_l';
  src: url('../assets/fonts/Pretendard-Light.otf');
  font-weight: 300;
}
@font-face {
  font-family: 'Pretendard_ll';
  src: url('../assets/fonts/Pretendard-ExtraLight.otf');
  font-weight: 200;
}
