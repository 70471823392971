.fitst-txt {
  background-color: #4a4a4a; /* #5ab7e2와 어울리는 밝은 파스텔톤 */
  color: #fff; /* 텍스트 색상: 조화를 이루는 진한 블루 */
  margin: 0 auto;
  font-size: 0.95rem;
  padding: 15px 10px;
  //font-family: 'Pretendard_bb';
  letter-spacing: 1.2px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  & span {
    animation: blink 2.5s infinite; /* 깜빡이는 애니메이션 추가 */
  }
}

/* 각 섹션 */
.scroll-section {
  height: 100%;
  scroll-snap-align: start;
  align-items: center;
  font-size: 1.55em;
  position: relative;

  &.second {
    background-color: #e4eef9; /* 첫 번째 섹션 배경색 */

    .second-section {
      text-align: center;
      margin-top: 56px;

      .promotion-logo-wrap {
        position: relative;

        .promotion-logo {
          background-image: url('../assets/images/promotion_logo.png');
          width: 160px;
          height: 70px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: left;
          margin-left: 10px;
          margin-top: -5px;
        }
      }

      h2 {
        letter-spacing: 1.2px;
        font-size: 0.9em;
        margin-bottom: 12px;
        margin-top: 20px;
        //font-family: 'Pretendard_bb';
        font-weight: bold;
        color: #1e1717;
        //word-wrap: break-word; /* 긴 단어를 줄바꿈 */
        //white-space: normal; /* 텍스트가 자동 줄바꿈 가능 */
        //width: 100%; /* 가로 100% */
      }

      h4 {
        color: #333;
        font-size: 0.6em;
        margin-bottom: 20px;
        letter-spacing: 1.5px;
        line-height: 1.3;
        font-family: 'Pretendard_l';
      }
      .box1 {
        margin-top: 25px;
      }
      .box1,
      .box2,
      .box3 {
        h3 {
          color: #447efa;
        }

        p {
          color: #000;
          font-size: 13.9px;
        }
      }
      .box1,
      .box2,
      .box3 {
        padding: 23px;
        background-color: #fff;
        margin-bottom: 20px;
        border-radius: 22px;
        font-size: 0.6em;
        line-height: 1.6;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
      }

      .box3 {
        margin-bottom: 50px;
      }

      .img-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        .check_ok {
          display: inline-block;
          vertical-align: middle;
          margin-top: -4px;
          background-image: url('./first_check.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }
      }
    }
  }

  .all-product-wrap {
    border: 2px solid #447efa;
    margin-top: 30px;
    padding: 15px 20px;
    border-radius: 22px;
    color: #333;
    background-color: #f8f8f8;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);

    .all-product-content {
      font-size: 0.7em;
      text-align: center;
      font-family: 'Pretendard_b';
      line-height: 1.2;
      letter-spacing: 1.1px;

      span {
        color: #447efa;
        font-size: 1.3em;
      }
    }
  }

  & .plus-section {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.7)), url('./medical.gif'); /* 원본 색상을 기반으로 하단 흐릿하게 처리 */
    background-size: cover; /* GIF 크기 맞춤 */
    background-position: center; /* GIF 중앙 정렬 */
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .image-box {
      width: 100%;
      height: 150px;
      display: block;
      flex-shrink: 0;
      overflow: hidden;
      padding: 20px;
    }

    .responsive-txt {
      color: #fff;
      font-size: 0.9em;
      margin-bottom: 20px;
      font-family: 'Pretendard_bb';
      text-align: center;
      padding-top: 30px;
      letter-spacing: 1.2px;
    }
  }

  /* Product Section 스타일 */
  & .product-section {
    background: linear-gradient(360deg, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.7)), url('./blue_background.png'); /* 원본 색상을 기반으로 하단 흐릿하게 처리 */
    background-size: cover; /* 배경 이미지 크기 맞춤 */
    background-position: center; /* 배경 이미지 중앙 정렬 */
    display: flex;
    flex-direction: column;
    gap: 20px; /* 각 박스 간의 간격 */
    width: 100%; /* 부모 너비 맞춤 */
    max-width: 800px; /* 박스 최대 너비 제한 */
    padding: 30px 25px 50px 25px;

    .image-wrap {
      display: flex;
      flex-direction: column; /* 이미지와 텍스트를 세로 정렬 */
      align-items: center;
      background: #fff; /* 박스 배경 흰색 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); // 그림자
      overflow: hidden; /* 박스 초과 영역 숨김 */
      width: 100%; /* 부모 너비 맞춤 */
      border-radius: 22px;

      .product-img1,
      .product-img2,
      .product-img3,
      .product-img4 {
        width: 100%; /* 이미지 너비 */
        height: 200px; /* 고정 높이 */
        background-size: cover; /* 이미지 크기 맞춤 */
        background-position: center; /* 중앙 정렬 */
        background-repeat: no-repeat;
        position: relative; /* 가상 요소를 위한 부모 요소 */
        overflow: hidden; /* 가상 요소가 범위를 넘지 않도록 */
      }

      .product-img1::before,
      .product-img2::before,
      .product-img3::before,
      .product-img4::before {
        content: ''; /* 가상 요소 생성 */
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2); /* 어둡게 처리 (투명도 조정 가능) */
        z-index: 1; /* 이미지 위에 표시 */
      }

      .product-img1,
      .product-img2,
      .product-img3,
      .product-img4 {
        z-index: 0; /* 이미지 뒤로 보내기 */
      }

      /* 개별 이미지 설정 */
      .product-img1 {
        background-image: url('./vip_pro.png'); /* 이미지 경로 */
      }
      .product-img2 {
        background-image: url('./pre_pro.png');
      }
      .product-img3 {
        background-image: url('./nob_pro.png');
      }
      .product-img4 {
        background-image: url('./smt_pro.png');
      }

      .product-title {
        padding-top: 15px;
        font-family: 'Pretendard_b';
        color: #000;
        font-size: 0.9em;
        letter-spacing: 1.1px;
      }
      .product-txt {
        padding-top: 3px; /* 텍스트 내부 여백 */
        padding-bottom: 15px;
        text-align: center; /* 텍스트 가운데 정렬 */
        font-family: 'Pretendard_m';
        color: #333; /* 텍스트 색상 */
        font-size: 0.6em; /* 텍스트 크기 */
        line-height: 1.5; /* 줄 간격 */
        background-color: #fff; /* 배경 흰색 */
      }
    }
  }

  /* 반응형 처리 */
  @media (min-width: 768px) {
    .plus-section {
      flex-direction: row; /* 태블릿 이상에서는 가로 정렬 */
      flex-wrap: wrap; /* 이미지가 줄바꿈되도록 */
      justify-content: center; /* 가운데 정렬 */
      gap: 15px; /* 이미지 간격 */

      .image-box {
        width: calc(50% - 15px); /* 태블릿 이상에서는 한 줄에 2개 */
        max-width: 300px; /* 최대 크기 제한 */
      }
    }

    .product-section {
      .image-wrap {
        flex-direction: row; /* 태블릿 이상에서는 가로 정렬 */
        align-items: flex-start; /* 위쪽 정렬 */
        gap: 20px; /* 요소 간 간격 */
      }
    }
  }

  .product-price {
    width: 100%; /* 가로 100% */
    font-size: 0.58em;
    margin-bottom: 20px;
    text-align: center;
    padding: 0 20px;

    .normal-price,
    .sale-price {
      padding: 6px 12px;
      border-radius: 12px;
      letter-spacing: 1.1px;
    }
    .normal-price {
      border: 1px solid #c6c6c6;
      background-color: #fafafa;
      font-family: 'Pretendard_l';
    }
    .sale-price {
      margin-top: 5px;
      border: 1px solid #57a7ff;
      background-color: #f4f9ff;
      font-family: 'Pretendard_bb';
    }
  }

  @media (min-width: 1024px) {
    .plus-section .image-box {
      width: calc(25% - 15px); /* 데스크톱에서는 한 줄에 4개 */
      max-width: 250px;
    }
  }

  &.third {
    background-color: #f8f8f8;

    .third-section {
      text-align: center;
      padding: 50px 20px 0 20px;

      h2 {
        letter-spacing: 1.2px;
        font-size: 0.9em;
        margin-bottom: 30px;
        color: #000;
      }

      .box4,
      .box5,
      .box6 {
        position: relative; // 공통 부모 설정
        padding: 20px; // 텍스트 여백
        background-color: #fff; // 배경색
        margin-bottom: 20px; // 박스 간격
        border-radius: 22px; // 둥근 모서리
        font-size: 0.6em; // 폰트 크기
        line-height: 1.6; // 줄 간격
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); // 그림자
        overflow: hidden; // 이미지 자르기
        min-height: 152px; // 박스 최소 높이
        display: flex; // Flexbox 활성화
        flex-direction: column; // 세로 방향 정렬
        justify-content: center; // 세로 중앙 정렬
        align-items: center;

        .background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%; // 부모의 너비와 높이를 완전히 채움
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover; // 이미지가 부모를 완전히 덮도록 설정
          background-position: center; // 이미지를 중앙에 맞춤
          filter: brightness(35%); // 이미지 어둡게
        }

        h3 {
          position: relative;
          color: #447efa;
          font-family: 'Pretendard_bb';
        }

        p {
          position: relative; // 텍스트를 이미지 위에 표시
          z-index: 1; // 텍스트가 이미지 위에 오도록 설정
          color: #000; // 텍스트를 가독성 있게 흰색으로 설정
          font-family: 'Pretendard_m';
          font-size: 13.9px;
        }

        .img2-wrap {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-bottom: 10px;

          & .sp1 {
            background-image: url('./ope1.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 30px;
            height: 30px;
          }

          & .sp2 {
            background-image: url('./ope2.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 30px;
            height: 30px;
          }

          & .sp3 {
            background-image: url('./ope3.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 30px;
            height: 30px;
          }
        }
      }

      // 개별 배경 이미지 설정
      .box4 .background {
        background-image: url('./suc1.jpg');
      }

      .box5 .background {
        background-image: url('./suc2.jpg');
      }

      .box6 .background {
        background-image: url('./suc3.jpg');
      }

      .box6 {
        margin-bottom: 50px;
      }
    }
  }

  &.fourth {
    background-color: #f8f8f8;

    .fourth-section {
      text-align: center;
      padding: 50px 40px 0 40px;

      h2 {
        letter-spacing: 1.4px;
        font-size: 0.9em;
        margin-bottom: 30px;
        font-family: 'Pretendard_bb';
        color: #1e1717;
        line-height: 1.3;
        //word-wrap: break-word; /* 긴 단어를 줄바꿈 */
        //white-space: normal; /* 텍스트가 자동 줄바꿈 가능 */
        //width: 100%; /* 가로 100% */
      }

      h4 {
        color: #4f4f4f;
        font-size: 0.9em;
        margin-bottom: 10px;
        letter-spacing: 1.5px;
        line-height: 1.3;
        font-family: 'Pretendard_l';
      }

      .center-txt-left {
        width: fit-content; // 컨테이너의 크기를 텍스트 크기에 맞춤
        margin: 0 auto; // 가로 중앙 정렬 유지

        .checkinbox {
          display: inline-block;
          vertical-align: middle;
          background-image: url('./checkinbox.png');
          background-repeat: no-repeat;
          background-size: contain;
          width: 18px;
          height: 18px;
          margin-right: 2px;
          margin-top: -3px;
        }
        p {
          text-align: left; // p 태그 텍스트 좌측 정렬
          margin: 5px 0; // 간격 조정
          font-size: 1em;
          font-family: 'Pretendard_m';
        }
      }

      .tx-title1 {
        letter-spacing: 1.3px;
        font-family: 'Pretendard_bb';
        font-size: 1.3em;
      }

      .box7,
      .box8,
      .box9 {
        padding: 20px;
        background-color: #fff;
        margin-bottom: 20px;
        border-radius: 22px;
        font-size: 0.6em;
        line-height: 1.6;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      }
      .box8 {
        margin-bottom: 50px;
      }
    }
  }

  &.fifth {
    background-color: #e4eef9;

    .fifth-section {
      text-align: center;
      padding: 50px 40px 0 40px;

      h2 {
        letter-spacing: 1.4px;
        font-size: 0.9em;
        margin-bottom: 20px;
        font-family: 'Pretendard_bb';
      }

      h3 {
        letter-spacing: 1.4px;
        font-size: 1em;
        font-family: 'Pretendard_bb';
        text-align: left;
      }

      h4 {
        color: #4f4f4f;
        font-size: 0.7em;
        margin-bottom: 20px;
        letter-spacing: 1.5px;
        line-height: 1.3;
        font-family: 'Pretendard_l';
      }

      p {
        text-align: left;
        margin: 5px 0;
        font-size: 0.7em;
      }

      .box9,
      .box10,
      .box11 {
        padding: 20px;
        background-color: #fff;
        margin-bottom: 20px;
        border-radius: 22px;
        font-size: 0.8em;
        line-height: 1.6;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      }
      .box11 {
        margin-bottom: 50px;
      }
    }
  }

  &.sixth {
    background-color: #e4eef9;
    padding: 0 20px;
    position: relative;

    .sixth-section {
      text-align: center;

      h2 {
        font-family: 'Pretendard_bb';
        margin-bottom: 20px;
        font-size: 0.9em;
        color: #333;
      }
      p {
        font-family: 'Pretendard_m';
        padding-bottom: 35px;
        font-size: 0.8em;
        color: #333;
      }

      .box12 {
        min-height: 300px;
        padding-top: 10px;
        padding-bottom: 110px;
      }

      .animation-box {
        width: 80px;
        height: 70px;
        background-color: #447efa; // 강조되는 색상
        border-radius: 50%; // 초기값
        margin: 0 auto;
        animation: morph1 2s infinite alternate; // 모서리 움직임 애니메이션
        box-shadow: 0 0 20px rgba(37, 99, 235, 0.4); // 파스텔톤 효과
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .arrow-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px; // 화살표 간격 설정
        }

        .arrow {
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 10px solid white; // 화살표 색상
        }
      }
    }
  }
}

.fixed-reserv {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  background-color: black;
  display: flex; /* 플렉스박스 적용 */
  z-index: 99;
}

.fixed-left-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;

  & p {
    padding: 5px;
  }
}

.fixed-right-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: #ff6b6b; /* 눈에 띄는 배경색 */
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 약간의 그림자 효과 */
  color: #fff;
}

.fixed-left-box:hover {
  transition: background-color 0.3s ease; /* 부드러운 전환 효과 */
}

.fixed-left-box span {
  color: #ffffff; /* 텍스트 색상 */
  animation: pulse 1.1s infinite; /* 텍스트에만 애니메이션 적용 */
}

.fixed-left-box {
  background-color: #447efa;
  text-decoration: none;
  display: flex; /* Flexbox 사용 */
  align-items: center; /* 세로 가운데 정렬 */
  justify-content: center; /* 가로 가운데 정렬 */

  .promotion_reserv {
    display: inline-block;
    background-image: url('./promotion_reserv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
  }
}

.fixed-right-box {
  display: flex; /* Flexbox 사용 */
  align-items: center; /* 세로 가운데 정렬 */
  justify-content: center; /* 가로 가운데 정렬 */

  .promotion_tel {
    display: inline-block;
    background-image: url('./promotion_tel.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes morph1 {
  0% {
    border-radius: 50%; // 완전한 원
  }
  25% {
    border-radius: 50% 40% 50% 60%; // 좌측 상단과 우측 하단 변화
  }
  50% {
    border-radius: 40% 50% 60% 50%; // 좌측 하단과 우측 상단 변화
  }
  75% {
    border-radius: 50% 60% 50% 40%; // 다시 변화
  }
  100% {
    border-radius: 50%; // 원으로 돌아감
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1; /* 완전히 보임 */
  }
  50% {
    opacity: 0; /* 사라짐 */
  }
}
